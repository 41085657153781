import * as React from "react";
import Address from '../Address';
import * as request from 'arcdynamic-request';
import PaginateButtons from '../PaginateButtons';
import Results from '../Results';
import Modal from '../Modal';
import AddressForm from '../AddressForm';
import exc from '../../exception';
import { useState } from 'react';

const limitCount = 8;

function AddressListItem({ address, refresh, edit }) {
	const [isBusy, setIsBusy] = useState(null);

	function purge() {
		if (isBusy) return;

		setIsBusy(true);
		
		Promise.all([
			request(arc.path.store, {
				service: 'cart',
				action: 'store.Address.purge',
				params: [address.id],
			}).then(() => {
				// todo: error logging
			}),
			refresh(),
		])
		.then(() => {
			setIsBusy(null);
		})
		.catch(e => {
			setIsBusy(null);
			exc(e);
		});
	}

	return (
		<div className='AddressList_list_item'>
			<Address address={address}/>
			<div className='AddressList_control'>
				<button type='button' className='AddressList_control_btn' onClick={edit}>Edit</button>
				<span> | </span>
				<button type='button' className='AddressList_control_btn' onClick={purge} data-is-busy={isBusy}>Delete</button>
			</div>
		</div>
	)
}

export default class extends React.Component {
	state = {
		addresses: null,
		totalCount: null,
		page: 1,
		edit: false,
		editAddress: null,
	};

    componentDidMount() {
		this.getItems({
			page: 1,
		});
	}

    getItems = (params)=>{
		return request(arc.path.store, {
			service: 'cart',
			action: 'store.Address.get',
			options: {
				filter: {},
				limit: {
					count: limitCount,
					offset: (limitCount * params.page) - limitCount,
				},
				order: params.order || 'a-z',
				count: 2,
			},
		}).then((res)=>{
			this.setState({
				addresses: res.data,
				totalCount: res.count,
				page: params.page,
			});
		}).catch(exc)
	};

    didUpdate = (addr)=>{
		// Swap old address object for new address in the existing addresses array.
		const addresses = this.state.addresses.slice(0);
		addresses.splice(addresses.indexOf(this.state.editAddress), 1, addr);

		this.setState({
			edit: false,
			editAddress: false,
			addresses,
		});
	};

    didCreate = ()=>{
		this.getItems({page: this.state.page});
		this.setState({
			edit: false,
			editAddress: false,
		});
	};

    render() {
		if (!this.state.addresses) return <p>Loading…</p>;

		return (
			<div className='AddressList'>
				<div className='AddressList_new'>
					<button className='submit-button' onClick={()=> this.setState({edit: true, editAddress: null})}>Add new address</button>
				</div>
				<div className='AddressList_list'>
				{
					this.state.addresses.map(address => (
						<AddressListItem
							key={address.id}
							address={address}
							refresh={()=> this.getItems({page: this.state.page})}
							edit={()=> this.setState({edit: true, editAddress: address})}
						/>
					))
				}
				</div>
				<div className='AddressList_results'>
					<PaginateButtons onNext={()=>this.getItems({page: this.state.page+1})} onPrev={()=>this.getItems({page: this.state.page-1})} count={limitCount} page={this.state.page} totalCount={this.state.totalCount} itemCount={this.state.addresses.length}/>
					<Results count={limitCount} page={this.state.page} totalCount={this.state.totalCount} itemCount={this.state.addresses.length}/>
				</div>
				{
					this.state.edit ? (
						<Modal onClose={()=> this.setState({edit: false, editAddress: null})}>
							<h2>{this.state.editAddress ? 'Edit Address' : 'New Address'}</h2>
							<AddressForm address={this.state.editAddress} onSuccess={this.state.editAddress ? this.didUpdate : this.didCreate} onCancel={()=> this.setState({edit: false, editAddress: null})} isShippingAddress={true}/>
						</Modal>
					) : null
				}
			</div>
		);
	}
}
