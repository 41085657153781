import * as React from "react";
import Summary from '../Summary';
import Address from '../Address';
import OrderDetailProduct from '../OrderDetailProduct';

function formatDate(unixTimestamp) {
	const d = new Date(unixTimestamp*1000);
	return `${d.getUTCMonth()+1}/${d.getUTCDate()}/${d.getUTCFullYear()}`;
}

export interface Props {
	order: any,
	hideSummary?: boolean,
}

export default class extends React.Component<Props> {
    render() {
		const order = this.props.order;

		if (!order) return null;

		return (
			<div className='OrderDetail'>
				<div className='OrderDetail_head'>
					{
						order.created ? (
							<div className='OrderDetail_head_sec'>
								<div className='OrderDetail_head_sec_label'>Order Placed</div>
								{formatDate(order.created)}
							</div>
						) : null
					}
					{
						order.deliveryDate ? (
							<div className='OrderDetail_head_sec'>
								<div className='OrderDetail_head_sec_label'>Delivery date</div>
								{formatDate(order.deliveryDate)}
							</div>
						) : (
							null
						)
					}					
					<div className='OrderDetail_head_sec'>
						<div className='OrderDetail_head_sec_label'>Status</div>
						<div className={`OrderDetail_head_sec_status OrderDetail_head_sec_status--${order.statusCode}`}>
							{order.statusCode[0].toUpperCase() + order.statusCode.slice(1)}
						</div>
					</div>
					<div className='OrderDetail_head_sec'>
						<div className='OrderDetail_head_sec_label'>Order Number</div>
						<strong>{order.purchaseOrderCode}</strong>
					</div>
				</div>
				<div className='OrderDetail_body' data-disabled={order.statusCode === 'cancelled'}>
					<div className='OrderDetail_products'>
						{
							order.products.map(el => <OrderDetailProduct key={el.__type+el.productId} product={el}/>)
						}
					</div>
					<div className='OrderDetail_ship'>
						<h2>Ship to</h2>
						<Address address={order.address}/>
					</div>
					{
						this.props.hideSummary ? null : (
							<div className='OrderDetail_summary'>
								<h2>Summary</h2>
								<Summary order={order}/>
							</div>
						)
					}
				</div>
			</div>
		);
	}
}
