import * as React from "react";
import OrderGiftMessage from '../OrderGiftMessage';
import OrderShippingInstruction from '../OrderShippingInstruction';
import OrderSubscription from '../OrderSubscription';
import CheckoutShipping from '../CheckoutShipping';
import GuestCheckoutShipping from '../GuestCheckoutShipping';
import CheckoutDelivery from '../CheckoutDelivery';
import Address from '../Address';

export interface Props {
	order: any,
	cart: any,
	fetchAddresses?: any,
	addresses?: any,
	title?: string,
	isGuest: boolean,
	purgeAddress: any,
	message: string,
	alerts: any[],
	setMessage: any,
	messageEnabled: boolean,
	setMessageEnabled: any,
	shippingInstructions: string,
	setShippingInstructions: any,
}

export default class extends React.Component<Props> {
    state = {
        editShipping: this.props.order.shippingAddressId ? false : true,
    };

	private success = () => {
		this.setState({editShipping: false});
	};

    render() {
		const order = this.props.order;

		return (
			<div className='CheckoutOrder'>
				<hr/>
				{
					this.props.title ? (<h2 className='CheckoutOrder_title'>{this.props.title}</h2>) : null
				}
				{
					this.state.editShipping ? (this.props.isGuest ? (
						<div key={1}>
							<h2>Select Shipping Address</h2>
							<GuestCheckoutShipping onSuccess={this.success} shippingAddress={order.shippingAddress} orderIndex={order.orderIndex}/>
						</div>
					) : (
						<div key={2}>
							<h2>Select Shipping Address</h2>
							<CheckoutShipping onSuccess={this.success} fetchAddresses={this.props.fetchAddresses} addresses={this.props.addresses} orderIndex={order.orderIndex} purgeAddress={this.props.purgeAddress}/>
						</div>
					)) : null
				}
				{
					!this.state.editShipping && order.shippingAddress ? (
						<div key={3}>
							<h2>Shipping Address</h2>
							<div className='CheckoutOrder_address'>
								<Address address={order.shippingAddress}/>
								<button className='CheckoutOrder_address_btn' type='button' onClick={()=> this.setState({editShipping: true})}>Edit address</button>
							</div>
							<div>
								<CheckoutDelivery order={order}/>
							</div>
						</div>
					) : null 
				}
				<div hidden={this.state.editShipping}>
					{
						arc.shippingInstructions.enabled && <OrderShippingInstruction value={this.props.shippingInstructions} setShippingInstructions={this.props.setShippingInstructions}/>
					}
					{
						arc.giftMessage.enabled && (
							<OrderGiftMessage
								value={this.props.message}
								alerts={this.props.alerts}
								setMessage={this.props.setMessage}
								messageEnabled={this.props.messageEnabled}
								setMessageEnabled={this.props.setMessageEnabled}
							/>
						)
					}
					{
						order.subscription && order.subscriptionAmount > 0 && arc.subscriptions.autoRenew ? (
							<OrderSubscription autoRenewStatusCode={order.subscription.autoRenewStatusCode} orderIndex={order.orderIndex}/>
						) : null
					}
				</div>
			</div>
		);
	}
}