import * as React from "react";
import Alert from '../Alert';
import * as request from 'arcdynamic-request';
import exc from '../../exception';

export interface Props {
	onRequest: any,
}

export default class extends React.Component<Props> {
	state = {
		error: null,
	};

    handleSubmit = (e)=>{
		e.preventDefault();

		request(arc.path.store, {
			service: 'cart',
			action: 'store.Customer.requestResetPassword',
			params: [(this.refs.username as HTMLInputElement).value],
		}).then((res)=>{
			if (res && res.success) {
				this.setState({
					error: null,
				});
				this.props.onRequest();
			} else {
				this.setState({
					error: res && res.message ? res.message : 'Your request could not be completed.',
				})
			}
		}).catch(exc);
	};

    render() {
		return (
			<div className='ForgotPassword_Request'>
				<h1>Password Reset</h1>
				<form onSubmit={this.handleSubmit}>
					<input ref='username' type='text' autoFocus placeholder='Enter Email' autoComplete='email' required/> <button>Submit</button>
				</form>
				{this.state.error ? (
					<div className='ForgotPassword_Request_error'>
						<Alert type='error'>{this.state.error}</Alert>
					</div>
				) : null}
				<ul>
					<li>Confirmation email make take up to 5 minutes to deliver.</li>
					<li>If you don't see the email, check other places it might be, like your spam, social, or other folders.</li>
				</ul>
			</div>
		);
	}
}
