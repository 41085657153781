import * as React from "react";
import * as request from 'arcdynamic-request';
import { setUser } from '../../actions';
import Alert from '../Alert';
import exc from '../../exception';

export interface Props {
	user?: any,
}

export default class extends React.Component<Props> {
    state = {
		edit: null,
		isBusy: null,
		message: null,
	};

	private email: HTMLInputElement;
	private firstName: HTMLInputElement;
	private lastName: HTMLInputElement;
	private password: HTMLInputElement;
	private confirmPassword: HTMLInputElement;

    update = (options)=>{
		this.setState({isBusy: true});

		return request(arc.path.store, {
			service: 'cart',
			action: 'store.Customer.update',
			params: [options],
		}).then(res => {
			this.setState({isBusy: null});

			if (res && res.success) {
				this.setState({
					edit: null,
					message: null,
				});
				setUser(res.data);
			} else {
				this.setState({
					message: res.message,
				});
			}
		}).catch(exc);
	};

    handleName = (e)=>{
		e.preventDefault();

		const firstName = this.firstName.value;
		const lastName = this.lastName.value;

		if (firstName === this.props.user.person.firstName && lastName === this.props.user.person.lastName) {
			this.setState({
				edit: null,
			});
			return;
		}

		this.update({
			value: {
				person: {
					value: {
						firstName,
						lastName,
					}
				}
			}
		});
	};

    handleEmail = (e)=>{
		e.preventDefault();

		const email = this.email.value;
		const username = this.email.value;

		if (email === this.props.user.email) {
			this.setState({
				edit: null,
			});
			return;
		}

		this.update({
			value: {
				email,
				username,
			}
		});
	};

    handlePassword = (e)=>{
		e.preventDefault();

		const password = this.password.value;
		const confirmPassword = this.confirmPassword.value;

		if (!password && !confirmPassword) {
			this.setState({
				edit: null,
			});
			return;
		}

		if (password !== confirmPassword) {
			this.setState({
				message: 'Passwords do not match',
			});
			return;
		}

		this.update({
			value: {
				password,
			}
		});
	};

    render() {
		const user = this.props.user;

		const sections = [
			{
				name: 'Name',
				onSubmit: this.handleName,
				key: 'name',
				display: (
					<div>{user.person.firstName} {user.person.lastName}</div>
				),
				edit: (
					<div>
						<input ref={x => this.firstName = x} autoFocus required type='text' defaultValue={user.person.firstName} placeholder='First Name' autoComplete='given-name'/> 
						<input ref={x => this.lastName = x} required type='text' defaultValue={user.person.lastName} placeholder='Last Name' autoComplete='family-name'/>
					</div>
				),
			},
			{
				name: 'Email',
				onSubmit: this.handleEmail,
				key: 'email',
				display: (
					<div>{user.email || 'No email provided'}</div>
				),
				edit: (
					<div className='UserInfo_detail_email'>
						<input ref={x => this.email = x} autoFocus type='email' defaultValue={user.email} placeholder='Email' autoComplete='email'/>
					</div>
				),
			},
			{
				name: 'Password',
				onSubmit: this.handlePassword,
				key: 'password',
				display: (
					<div>*****</div>
				),
				edit: (
					<div>
						<input ref={x => this.password = x} autoFocus type='password' defaultValue='' placeholder='New password' autoComplete='new-password'/> 
						<input ref={x => this.confirmPassword} type='password' defaultValue='' placeholder='Re-enter password' autoComplete='new-password'/>
					</div>
				),
			},
		];


		return (
			<div className='UserInfo'>
				{
					sections.map(el => (
						<form key={el.key} onSubmit={el.onSubmit} className='UserInfo_item'>
							<div className='UserInfo_head'>{el.name}</div>
							<div className='UserInfo_body'>
								<div className='UserInfo_detail'>
									{
										this.state.edit === el.key ? el.edit : el.display
									}
									{
										this.state.edit === el.key && this.state.message ? (
											<div className='UserInfo_error'>
												<Alert type='error'>{this.state.message}</Alert>
											</div>
										) : null
									}
								</div>
								{
									this.state.edit === el.key ? (
										<button key={1} type='submit' className='submit-button' data-is-busy={this.state.isBusy}>Save</button>
									) : (
										<button key={2} type='button' onClick={()=> this.setState({edit: el.key, message: null})}>Edit</button>
									)
								}
							</div>
						</form>
					))
				}
			</div>
		);
	}
}
