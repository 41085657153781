import * as request from 'arcdynamic-request';
import cart from './cart';
import { setUser } from './actions'
import exc from './exception';

export default {
	login(username, password) {
		return Promise.all([
			request(arc.path.store, {
				service: 'cart',
				action: 'Authentication.login',
				params: [username, password],
			}),
			cart.getCart(),
			this.getUser(),
		]).then(values => {
			return values[0];
		}).catch(exc);
	},
	guestLogin(username) {
		return Promise.all([
			request(arc.path.store, {
				service: 'cart',
				action: 'Authentication.guestLogin',
				params: [username],
			}),
			cart.getCart(),
			this.getUser(),
		]).then(values => {
			return values[0];
		}).catch(exc);
	},
	getUser() {
		return request(arc.path.store, {
			service: 'cart',
			action: 'store.Customer.get',
		}).then((res)=>{
			setUser(res.data);
			return res;
		}).catch(exc);
	},
	logout() {
		return Promise.all([
			request(arc.path.store, {
				service: 'cart',
				action: 'Authentication.logout',
			}),
			cart.getCart(),
		]).then(values => {
			if (values[0] && values[0].success) {
				setUser(false);
			}
			return values[0];
		}).catch(exc);
	},
};
