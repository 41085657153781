import * as React from "react";
import Root from './components/Root';
import Cart from './components/Cart';
import CartColumn from './components/CartColumn';
import CartRestricted from './components/CartRestricted';
import Checkout from './components/Checkout';
import SelectPayment from './components/SelectPayment';
import OrderSent from './components/OrderSent';
import AccountRegister from './components/AccountRegister';
import ResetPassword from './components/ResetPassword';
import AccountRestricted from './components/AccountRestricted';
import GuestRestricted from './components/GuestRestricted';
import Account from './components/Account';
import OrderHistory from './components/OrderHistory';
import LoginPage from './components/LoginPage';
import history from './history';

const base = arc.path.cart;

export default {
	[base]: ({params, props}) => {
		document.title = 'Shopping Cart'

		return (
			<Root user={props.user} cart={props.cart} location={props.location}>
				<CartColumn {...props}>
					<Cart {...props}/>
				</CartColumn>
			</Root>
		)
	},
	[base+'checkout']: ({params, props}) => {
		document.title = 'Checkout';
		return (
			<Root user={props.user} cart={props.cart} location={props.location}>
				<AccountRestricted user={props.user}>
					<CartRestricted cart={props.cart} productOrders={props.productOrders}>
						<CartColumn {...props}>
							<Checkout {...props}/>
						</CartColumn>
					</CartRestricted>
				</AccountRestricted>
			</Root>
		)
	},
	[base+'payment']: ({params, props}) => {
		document.title = 'Payment'
		return (
			<Root user={props.user} cart={props.cart} location={props.location}>
				<AccountRestricted user={props.user}>
					<CartRestricted cart={props.cart} productOrders={props.productOrders}>
						<CartColumn {...props}>
							<SelectPayment {...props}/>
						</CartColumn>
					</CartRestricted>
				</AccountRestricted>
			</Root>
		)
	},
	[base+'complete']: ({params, props}) => {
		document.title = 'Thank You'
		return (
			<Root user={props.user} cart={props.cart} location={props.location}>
				<AccountRestricted user={props.user}>
					<OrderSent id={props.location.query.id}/>
				</AccountRestricted>
			</Root>
		)
	},
	[base+'account']: ({params, props}) => {
		document.title = 'Your Account'
		return (
			<Root user={props.user} cart={props.cart} location={props.location}>
				<GuestRestricted user={props.user}>
					<Account user={props.user}/>
				</GuestRestricted>
			</Root>
		)
	},
	[base+'account/order-history']: ({params, props}) => {
		document.title = 'Order History'
		return (
			<Root user={props.user} cart={props.cart} location={props.location}>
				<GuestRestricted user={props.user}>
					<OrderHistory/>
				</GuestRestricted>
			</Root>
		)
	},
	[base+'register']: ({params, props}) => {
		document.title = 'Create Login'
		return (
			<Root user={props.user} cart={props.cart} location={props.location}>
				<AccountRegister/>
			</Root>
		)
	},
	[base+'reset-password']: ({params, props}) => {
		document.title = 'Reset Password'
		return (
			<Root user={props.user} cart={props.cart} location={props.location}>
				<ResetPassword verificationKey={props.location.query.key}/>
			</Root>
		)
	},
	[base+'login']: ({params, props}) => {
		document.title = 'Login'

		if (props.user && props.user.typeCode !== 'guest') {
			history.replace(arc.path.cart)
			return <div/>
		}

		return (
			<Root user={props.user} cart={props.cart} location={props.location}>
				<LoginPage allowGuest={false} onSuccess={()=>{
					// todo: redirect to referring url
					window.location.href = arc.path.base;
				}}/>
			</Root>
		)
	},
};