import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import Input from '../Input';

declare const google: any

let script

function useMaps() {
	const [maps, setMaps] = useState((typeof google !== 'undefined' && google.maps) || false)

	useEffect(() => {
		if (!maps) {
			if (typeof google !== 'undefined' && google?.maps) {
				setMaps(google.maps)
			} else {
				window['gmaps_onload_callback_with_a_pathetic_way_of_avoiding_global_collisons'] = () => {
					if (typeof google !== 'undefined' && google?.maps) {
						setMaps(google.maps)
					}
				}

				if (!script) {
					script = document.createElement('script')
					script.src = '//maps.google.com/maps/api/js?callback=gmaps_onload_callback_with_a_pathetic_way_of_avoiding_global_collisons&libraries=places&key=' + arc.address.autoComplete.googleAPIKey;
					const x = document.getElementsByTagName('script')[0]
					x.parentNode.insertBefore(script, x)
				}
			}
		}
	}, [])

	return maps
}

function useAutoComplete({ inputNode, countries, onAutoComplete }) {
	const maps = useMaps();

	useEffect(()=>{
		if (!maps || !countries) return;

		let autocomplete = new maps.places.Autocomplete(inputNode.current, {
			componentRestrictions: {
				country: Object.keys(arc.address.countries).map(x => x.toLowerCase()),
			},
			fields: ['address_components', 'geometry'],
			types: ['address'],
		});

		function fillInAddress() {
			// Get the place details from the autocomplete object.
			const place = autocomplete.getPlace();
			const diff = {
				addressOne: '',
				postalCode: '',
			} as any;

			let countryCode;
			let stateCode;

			// Get each component of the address from the place details,
			// and then fill-in the corresponding field on the form.
			// place.address_components are google.maps.GeocoderAddressComponent objects
			// which are documented at http://goo.gle/3l5i5Mr
			for (const component of place.address_components as any) {
				const componentType = component.types[0];

				switch (componentType) {
					case 'street_number': {
						diff.addressOne = `${component.long_name} ${diff.addressOne}`;
						break;
					}

					case 'route': {
						diff.addressOne += component.long_name;
						break;
					}
					
					case 'postal_code': {
						diff.postalCode = component.long_name;
						break;
					}

					case 'locality':
						diff.city = component.long_name;
						break;

					case 'administrative_area_level_1': {
						stateCode = component.short_name;
						break;
					}

					case 'country':
						countryCode = component.short_name;
						break;
				}
			}

			const country = countryCode && countries?.find(x => x.code === countryCode)
			if (country) {
				diff.country = countryCode;

				if (stateCode && country?.states?.find(x => x.code === stateCode)) {
					diff.state = stateCode;
				}
			}

			onAutoComplete(diff);
		}

		autocomplete.addListener('place_changed', fillInAddress);
	}, [maps, countries]);
}

export default function AutoComplete ({onAutoComplete, countries, ...props}) {
	const inputNode = useRef(null);
	if (arc.address?.autoComplete?.enabled && arc.address?.autoComplete?.googleAPIKey) {
		useAutoComplete({inputNode, countries, onAutoComplete});
	}

	return (
		<Input {...props} ref={inputNode}/>
	)
};