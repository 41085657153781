import * as React from "react";
import Address from '../Address';
import CreditCardIcon from '../CreditCardIcon';

export default ({card}: {card: any})=> {
	return (
		<div className='CreditCard'>
			<div className='CreditCard_cc'>
				<div className='CreditCard_cc_logo'>
					<CreditCardIcon cardType={card.cardType}/>
				</div>
				<div className='CreditCard_cc_body'>
					<div>Card number: …{card.lastFour}</div>
					<div>Expires: {('0'+card.expirationMonth).slice(-2)}/{card.expirationYear}</div>
				</div>
			</div>
			{ card.address ? <Address address={card.address} /> : null }
		</div>
	)
};
