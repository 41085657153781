import * as React from "react";
import Alert from '../Alert';
import * as request from 'arcdynamic-request';
import exc from '../../exception';

export interface Props {
	onReset: any,
	verificationKey?: string,
}

export default class extends React.Component<Props> {
	state = {
		isBusy: null,
		error: null,
		success: null,
	};

	private password: HTMLInputElement;
	private confirmPassword: HTMLInputElement;
	private verificationKey: HTMLInputElement;

    reset = (verificationKey, password)=>{
		this.setState({isBusy: true});

		request(arc.path.store, {
			service: 'cart',
			action: 'store.Customer.resetPassword',
			params: [verificationKey, password],
		}).then((res)=>{
			if (res && res.success) {
				this.props.onReset();
			} else {
				this.setState({
					error: res.message,
					isBusy: null,
				});
			}
		}).catch(exc);
	};

    handleSubmit = (e)=>{
		e.preventDefault();
		
		const password = this.password.value;
		const confirmPassword = this.confirmPassword.value;
		if (password !== confirmPassword) {
			return  this.setState({error: 'Passwords do not match'});
		}

		const verificationKey = this.props.verificationKey || this.verificationKey.value;

		this.reset(verificationKey, password);
	};

    render() {
		return (
			<div className='Reset'>
				<h1>Reset Password</h1>
				<form onSubmit={this.handleSubmit}>
					{
						!this.props.verificationKey ? (
							<div className='Reset_field'>
								<input type='text' ref={x => this.verificationKey = x} required placeholder='Verification key'/>
							</div>
						) : null
					}
					<div className='Reset_field'>
						<input type='password' ref={x => this.password = x} required defaultValue='' placeholder='New password' autoComplete='new-password'/>
					</div>
					<div className='Reset_field'>
						<input type='password' ref={x => this.confirmPassword = x} required defaultValue='' placeholder='Re-enter password' autoComplete='new-password'/>
					</div>
					{
						this.state.error ? (
							<div className='Reset_error'>
								<Alert type='error'>{this.state.error}</Alert>
							</div>
						) : null 
					}
					<button data-is-busy={this.state.isBusy} className='Reset_btn Reset_btn--verify'>Submit</button>
				</form>
				<ul>
					<li>Password Requirement: Use at least 8 characters.</li>
					<li>Tip: Do not reuse the same password on other sites.</li>
				</ul>
			</div>
		);
	}
}
