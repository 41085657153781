import * as React from "react";

export interface Props {
	address: any,
}

export default ({address}: Props)=> (
	<div>
		<div><strong>{address.addressee}</strong></div>
		<div>{address.addressOne}, {address.city}, {address.state}, {address.postalCode}</div>
	</div>
);