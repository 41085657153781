import * as React from "react";

function phone(numberString) {
	return numberString ? numberString.replace(/(\d\d\d)(\d\d\d)(\d\d\d\d)/, '$1-$2-$3') : null;
}

export interface Props {
	address: any,
}

export default ({address}: Props)=> (
	<address>
		{address.attentionName ? <div>Attn: {address.attentionName}</div> : null}
		<div>{address.addressee}</div>
		{address.careOfName ? <div>c/o {address.careOfName}</div> : null}
		<div>{[address.addressOne, address.addressTwo, address.addressThree, address.addressFour].map((el,i)=> (
			<div key={i}>{el}</div>
		))}</div>
		<div>{address.city}, {address.state} {address.postalCode}</div>
		{address.phone ? (<div>Phone: {phone(address.phone)}</div>) : null}
	</address>
);
