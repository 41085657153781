import * as React from "react";
import history from '../../history';

export interface Props {
	productOrders?: any,
	cart?: any,
}

export default class extends React.Component<Props> {
    componentDidMount() {
		this.redirect();
	}

    componentDidUpdate() {
		this.redirect();
	}

    redirect = () => {
		if (this.props.cart === false || (this.props.productOrders && !this.props.productOrders.length)) {
			history.replace(arc.path.cart);
		}
	};

    render() {
		return this.props.productOrders ? this.props.children : null;
	}
}