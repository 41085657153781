import * as React from "react";

export interface Props {
	onClose: any,
}

export default class extends React.Component<Props> {
	activeElement;

    componentDidMount() {
		// Disable the document scrollbar & prevent layout shift
		const root = document.querySelector('html');
		root.style.paddingRight = (window.innerWidth - document.documentElement.clientWidth)+'px';
		root.style.overflow = 'hidden';

		// Remember the currently focused element
		this.activeElement = document.activeElement;

		// Close on Esc keypress
		document.addEventListener('keydown', this.handleEsc);
	}

    componentWillUnmount() {
		// Enable scrollbar
		const root = document.querySelector('html');
		root.style.overflow = '';
		root.style.paddingRight = '';

		// Restore previously focused element
		if (this.activeElement) {
			this.activeElement.focus();
		}

		// Unbind events
		document.removeEventListener('keydown', this.handleEsc);
	}

    handleEsc = (e) => {
		if (e.keyCode === 27) {
			e.preventDefault();
			this.props.onClose();
		}
	};

    render() {
		return (
			<div className='Modal' onClick={(e)=> {
					if (e.target === e.currentTarget) {
						this.props.onClose();
					}
				}}>
				<div className='Modal_body'>
					<div className='Modal_content'>
						{this.props.children}
					</div>
					<button type='button' className='Modal_close' onClick={this.props.onClose}>×</button>
				</div>
			</div>
		);
	}
}