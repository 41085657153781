import * as React from "react";
import CartTabs from '../CartTabs';

export interface Props {
	user: any,
	heading: string,
	location: any,
}

// todo: enable changing address
export default ({user, heading, location}: Props)=> {
	let email = null;

	if (user && user.email) {
		if (user.typeCode === 'guest') {
			email = (<span>Email: {user.email}</span>)
		} else  {
			email = (<span>Email: <a href={arc.path.cart+'account'}>{user.email}</a></span>)
		}
	}

	return (
		<div className='CartHeading'>
			<h1>{heading}</h1>
			<CartTabs location={location}>{email}</CartTabs>
		</div>
	);
}