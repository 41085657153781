import * as React from "react";
import FormContainer from '../FormContainer';
import FormSection from '../FormSection';
import FormSubSection from '../FormSubSection';
import FormRadio from '../FormRadio';
import AddressForm from '../AddressForm';
import cart from '../../cart';
import * as request from 'arcdynamic-request';
import AddressCompact from '../AddressCompact';
import Alert from '../Alert';
import exc from '../../exception';

function setOrderShippingAddress({orderIndex, shippingAddressId}: {orderIndex: number, shippingAddressId: number}) {
	return request(arc.path.store, {
		service: 'cart',
		action: 'store.Cart.setOrderShippingAddress',
		params: [orderIndex, shippingAddressId],
	}).catch(exc);
}

export interface Props {
	orderIndex: number,
	fetchAddresses: any,
	addresses?: any[],
	purgeAddress?: any,
	onSuccess: any,
}

export default class extends React.Component<Props> {
	state = {
		edit: false,
		newAddress: false,
		selected: null,
		isBusy: null,
		error: false,
	};

	savedAddress = (address) => {
		if (this.state.isBusy) return;

		this.setState({
			isBusy: true,
			error: false,
		});

		Promise.all([
			setOrderShippingAddress({
				shippingAddressId: address.id, 
				orderIndex: this.props.orderIndex
			}).then(res => {
				if (res.success) {
					this.props.onSuccess();
					this.setState({isBusy: null});
				} else {
					this.setState({
						error: res.message || 'Could not assign address to order.',
						isBusy: null,
					});
				}
			}),
			this.props.fetchAddresses(),
			cart.getCart(),
		]);
	};

    handleSubmit = (e)=>{
		e.preventDefault();

		if (this.state.isBusy) return;

		this.setState({
			isBusy: true,
			error: false,
		});

		Promise.all([
			setOrderShippingAddress({
				shippingAddressId: this.state.selected, 
				orderIndex: this.props.orderIndex
			}).then(res => {
				if (res.success) {
					this.props.onSuccess();
					this.setState({isBusy: null});
				} else {
					this.setState({
						error: res.message || 'Could not assign address to order.',
						isBusy: null,
					});
				}
			}),
			cart.getCart(),
		]);
	};

    handleSelect = (e)=>{
		const id = e.currentTarget.value;

		this.setState({
			error: false,
			selected: id == 'new' ? null : id,
			newAddress: id == 'new',
			edit: this.state.edit == id ? id : false,
		});
	};

    renderAddressForm = (address)=>{
		return (
			<FormSubSection>
				<AddressForm address={address} onSuccess={this.savedAddress} saveAddress={true} isShippingAddress={true}/>
				{
					this.state.error ? <div className='CheckoutShipping_alert'><Alert type='error'>{this.state.error}</Alert></div> : null
				}
			</FormSubSection>
		);
	};

    purgeAddress = (address)=>{
		if (this.state.isBusy) return;

		this.setState({isBusy: true});

		Promise.all([
			this.props.purgeAddress(address),
			cart.getCart(),
		]).then(()=> {
			this.setState({isBusy: null});
		}).catch(exc);
	};

    render() {
		return (
			<FormContainer>
				<form className='CheckoutShipping' onSubmit={this.handleSubmit} data-is-busy={this.state.isBusy}>
					<FormSection>
						<FormRadio>
							<input required name='shipping-address-id' type='radio' value='new' onChange={this.handleSelect}/>
							<div>New Address</div>
						</FormRadio>
						{
							this.state.newAddress ? this.renderAddressForm(null) : null
						}
					</FormSection>
					{
						this.props.addresses ? this.props.addresses.map(el => (
							<FormSection key={el.id}>
								<FormRadio key={el.id}>
									<input required name='shipping-address-id' type='radio' value={el.id} onChange={this.handleSelect}/>
									{
										this.state.edit == el.id ? null : <AddressCompact address={el}/>
									}	
								</FormRadio>
								{
									this.state.edit == el.id ? this.renderAddressForm(el) : 
									this.state.selected == el.id ? (
										<div>
											{
												this.state.error ? <div className='CheckoutShipping_alert'><Alert type='error'>{this.state.error}</Alert></div> : null
											}
											<div className='CheckoutShipping_buttons'>
												<button className='submit-button'>Ship to this address</button> 
												{
													this.state.edit == el.id ? (
														<button type='button' onClick={()=> this.setState({edit: false})}>Cancel</button>
													) : (
														<button type='button' onClick={()=> this.setState({edit: el.id})}>Edit</button>
													)
												}
												<button type='button' onClick={()=> this.purgeAddress(el)}>Delete</button>
											</div>
										</div>
									) : null
								}
							</FormSection>
						)) : null
					}
				</form>
			</FormContainer>
		);
	}
}
