import * as React from "react";
import AddressForm from '../AddressForm';
import FormContainer from '../FormContainer';
import FormElement from '../FormElement';
import FormSection from '../FormSection';
import FormSubSection from '../FormSubSection';
import FormRadio from '../FormRadio';
import AddressCompact from '../AddressCompact';
import * as getCardTypes from 'credit-card-type';
import Address from '../Address';
import CreditCardIcon from '../CreditCardIcon';

export interface Props {
	addresses?: any[],
	card?: any,
}

export default class extends React.Component<Props> {
    static defaultProps = {
        addresses: [],
    };

    private cardNumber;
    private expirationMonth;
    private expirationYear;
    private cardCode;

    state = {
    	selectedRadio: null,
    	addresses: null,
    	selectedAddress: (()=> this.props.card && this.props.card.address ? this.props.addresses.filter(x => x.id == this.props.card.address.id).pop() : null)(),
    	cardType: null,
    }

    getData = () => {
		return new Promise((resolve, reject) => {
			const data = {
				...this.props.card,
				cardNumber: this.cardNumber.value,
				expirationMonth: this.expirationMonth.value,
				expirationYear: this.expirationYear.value,
				cardCode: this.cardCode.value,
			};

			if (this.state.selectedRadio === 'new') {
				(this.refs.billingAddress as any).save().then((address) => {
					if (address) {
						data.address = address;
						resolve(data);
					} else {
						resolve(false);
					}
				})
			} else if (this.state.selectedAddress.id) {
				data.addressId = this.state.selectedAddress.id;
				resolve(data);
			} else {
				data.address = this.state.selectedAddress;
				resolve(data);
			}
		})
	};

    handleCardLogo = (e) => {
		const types = getCardTypes(e.target.value.replace(/[^0-9]+/g, ''));

		this.setState({
			cardType: types.length === 1 ? types[0] : null,
		})
	};

    render() {
		let card = this.props.card || {};

		// current year, minus a day, to avoid any timezone issue
		const date = new Date();
		date.setDate(date.getDate() - 1);
		const year = date.getFullYear();
		const years = [];
		for (let i = 0; i < 15; i++) {
			years.push(year+i);
		}

		return (
			<div className='CreditCardForm'>
				<h2>Credit Card</h2>
				<FormContainer>
					<FormElement label='Card Number'>
						<div className='CreditCardForm_number'>
							<input
								ref={x => this.cardNumber = x}
								autoFocus
								disabled={card.lastFour?true:false}
								required
								name='cardNumber'
								type='text'
								autoComplete='cc-number'
								defaultValue={card.lastFour ? '...'+card.lastFour : ''}
								onChange={this.handleCardLogo}
								placeholder='Card number'
							/>
							<span className='CreditCardForm_logo'>
								<CreditCardIcon cardType={this.state.cardType ? this.state.cardType.type : null}/>
							</span>
						</div>
					</FormElement>
					<FormSection>
						<div className='CreditCardForm_exp'>
							<label className='CreditCardForm_label' htmlFor='expirationMonth'>
								Expiration
							</label>
							<select ref={x => this.expirationMonth = x} id='expirationMonth' name='expirationMonth' autoComplete='cc-exp-month' defaultValue={card.expirationMonth}>
								<option value='1'>01</option>
								<option value='2'>02</option>
								<option value='3'>03</option>
								<option value='4'>04</option>
								<option value='5'>05</option>
								<option value='6'>06</option>
								<option value='7'>07</option>
								<option value='8'>08</option>
								<option value='9'>09</option>
								<option value='10'>10</option>
								<option value='11'>11</option>
								<option value='12'>12</option>
							</select>
							<select required ref={x => this.expirationYear = x} name='expirationYear' autoComplete='cc-exp-year' defaultValue={card.expirationYear}>
								{
									years.map(val => <option key={val} value={val}>{val}</option>)
								}
							</select>
						</div>
					</FormSection>
					<FormElement label='Security Code'>
						<input ref={x => this.cardCode = x} className='CreditCardForm_cvv' placeholder={this.state.cardType ? this.state.cardType.code.name : 'CVC'} required name='cardCode' pattern='^[0-9]{3,4}$'  autoComplete='cc-csc' defaultValue='' title='3 to 4 digit security code' type='text' />
					</FormElement>
				</FormContainer>

				{
					this.state.selectedAddress ? (
						<div>
							<h2>Billing Address</h2>
							<div className='CreditCardForm_selected-addr'>
								<Address address={this.state.selectedAddress}/>
								<button type='button' onClick={()=> this.setState({selectedAddress: false})}>Select different address</button>
							</div>
						</div>
					) : (
						<div>
							<h2>Select Billing Address</h2>
							<FormContainer>
								<FormSection>
									<FormRadio>
										<input type='radio' name='cc-radio' required checked={this.state.selectedRadio === 'new'} value='new' onChange={()=> this.setState({selectedRadio: 'new'})}/>
										<div>New Address</div>
									</FormRadio>
									{
										this.state.selectedRadio === 'new' ? (
											<FormSubSection>
												<AddressForm
													ref='billingAddress'
													address={this.state.selectedAddress}
													isShippingAddress={false}
													saveAddress={false}
													onSuccess={address => {
														this.setState({
															selectedRadio: address.id,
															selectedAddress: address,
														});
													}
												}/>
											</FormSubSection>
										) : null
									}
								</FormSection>
								{
									this.props.addresses.map(el => (
										<FormSection key={el.id}>
											<FormRadio key={el.id}>
												<input name='cc-radio' required checked={this.state.selectedRadio == el.id} type='radio' value={el.id} onChange={()=>{
													this.setState({
														selectedRadio: el.id,
														selectedAddress: el,
													});
												}}/>
												<AddressCompact address={el}/>
											</FormRadio>
										</FormSection>
									))
								}
							</FormContainer>
						</div>
					)
				}
			</div>
		);
	}
}