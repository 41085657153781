export default {
	path: {
		base: '/',
		store: 'https://api.davidscookies.com/apps/cart/stores/dc/services/',
		cart: '/cart/',
		product: '/product/',
		return: '/',
		logo: '/img/logo.svg',
		cartApp: '/cart/main.js',
	},
	address: {
		shippingAddressNote: 'We cannot ship to PO/APO/FPO addresses',
		autoComplete: {
			enabled: false,
			googleAPIKey: false,
		},
		countries: {
			'US': {
				name: 'United States',
				excludeTerritories: true,
				validate: true,
				validatePostalCode: true
			}
		},
		fields: {
			addressee: {
				label: 'Full Name',
			},
			attention: {
				label: 'Attention',
			},
			type: {
				enabled: false,
			},
		},
	},
	requireShippingMethod: true,
	shippingInstructions: {
		enabled: true,
	},
	giftMessage: {
		enabled: true,
		isRequired: true,
		showIcon: true,
		label: 'Add a Free Gift Message',
		remindToSign: true,
		characterLimit: 400,
	},
	giftCard: {
		enabled: true,
	},
	subscriptions: {
		autoRenew: true,
	},
	aside: {
		message: false,
	},
	shippingInfo: false,
	desc: 'Store Description',
	port: 80,
	cartName: 'Your Cart',
	orderName: 'Your Order',
}