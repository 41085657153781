import * as React from "react";

export interface Props {
	count: number,
	page: number,
	itemCount: number,
	totalCount: number|false,
}

export default class extends React.Component<Props> {
    render() {
		let showing = null;

		if (this.props.itemCount > 0){
			const start = (this.props.page * this.props.count) - this.props.count + 1;
			const end = start + this.props.itemCount - 1;

			showing = (
				<span>
					Showing {start.toLocaleString()}–{end.toLocaleString()} {this.props.totalCount ? ' of '+this.props.totalCount.toLocaleString() : null}
				</span>
			);
		} else {
			showing = (<span>No results.</span>);
		}

		return (
			<div>
				{showing}
			</div>
		);
	}
}