import * as React from "react";
import Calendar from '../Calendar';
import getCheapestShippingMethod from '../../getCheapestShippingMethod';
import * as svgCircle from '../../svgs/circle.svg';
import currency from '../../currency';
import Icon from '../Icon';

export interface Props {
	shipping: any,
	deliveryDate: any,
	setMethod: any,
}

export default class extends React.Component<Props> {
    format = (date, month) => {
		const key = date.toISOString().slice(0,10);
		const calendar = this.props.shipping.calendar;
		let button = null;

		if (date.getUTCMonth() !== month) {
			button = (<div key={date.getTime()} className={'DeliveryCalendar_spacer'}></div>);
		} else if (calendar[key]) {
			const cheapestMethod = getCheapestShippingMethod(this.props.shipping.methods, calendar[key]);

			if (cheapestMethod) {
				let isActive = (this.props.deliveryDate && this.props.deliveryDate === key);

				button = (
					<button type='button' key={date.getTime()} onClick={()=>this.props.setMethod(cheapestMethod.id, key)} className={'DeliveryCalendar_day DeliveryCalendar_day--'+cheapestMethod.code+(isActive ? ' active' : '')}>
						<div className='DeliveryCalendar_day_date'>{date.getUTCDate()}</div>
						<div className='DeliveryCalendar_day_price'>{currency(cheapestMethod.amount)}</div>
					</button>
				);
			}
		}

		return button ? button : (
			<button type='button' disabled={true} key={date.getTime()} className={'DeliveryCalendar_day'}>
				<div className='DeliveryCalendar_day_date'>{date.getUTCDate()}</div>
				<div className='DeliveryCalendar_day_price'>&nbsp;</div>
			</button>
		);
	};

    render() {
		if (!this.props.shipping || !this.props.shipping.calendar) return null; // todo: need fallback

		const calendarKeys = Object.keys(this.props.shipping.calendar);
		const minDate = new Date(calendarKeys[0]);
		const maxDate = new Date(calendarKeys[calendarKeys.length-1]);
		const startDate = this.props.deliveryDate ? new Date(this.props.deliveryDate) : minDate; // NOTE: intentionally ignoring time offset on the deliveryDate
		const sortedMethods = this.props.shipping.methods.filter(el => el.code !== "overnight").sort((a,b)=> a.amount - b.amount);

		return (
			<div className='DeliveryCalendar'>
				<div className='DeliveryCalendar_legend'>
					{
						sortedMethods.map((el) => (
							<div key={el.code} className={`DeliveryCalendar_legend_item DeliveryCalendar_legend_item--${el.code}`}>
								<span className='DeliveryCalendar_legend_item_icon'><Icon svg={svgCircle}/></span> {el.name}
							</div>
						))
					}
					<div className='DeliveryCalendar_legend_item DeliveryCalendar_legend_item--unavailable'>
						<span className='DeliveryCalendar_legend_item_icon'><Icon svg={svgCircle}/></span> Unavailable
					</div>
				</div>
				<Calendar ref='calendar' format={this.format} minDate={minDate} maxDate={maxDate} date={startDate}/>
			</div>
		);
	}
}
