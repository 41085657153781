import * as React from "react";
import * as request from 'arcdynamic-request';
import Input from '../Input';
import Select from '../Select';
import Address from '../Address';
import Alert from '../Alert';
import AddressAutoComplete from '../AddressAutoComplete';
import exc from '../../exception';

const POBoxRX = /\b(?:p\.?\s*o\.?|post\s+office)(\s+)?(?:box|[0-9]*)?\b/i // PO regex taken from https://regexr.com/39d4l

const newAddress = {
	typeCode: '',
	addressee: '',
	attentionName: '',
	addressOne: '',
	addressTwo: '',
	country: '',
	city: '',
	state: '',
	postalCode: '',
	phone: '',
};

// const newAddress = {
// 	typeCode: 'residential',
// 	addressee: 'Test',
// 	attentionName: 'Tester',
// 	addressOne: '123 Fake Street',
// 	addressTwo: '',
// 	country: '',
// 	city: 'New York',
// 	state: 'NY',
// 	postalCode: '35666',
// 	phone: '',
// };

export interface Props {
	address?: any,
	autoFocus?: boolean,
	onSuccess?: any,
	onCancel?: any,
	isShippingAddress: boolean,
	submitButtonText?: string,
	saveAddress?: boolean,
}

function reducer(state, action) {
	switch (action.type) {
		case 'init': {			
			return {
				countries: [],
				address: (()=>{
					const address = { ...newAddress };
					if (action.address) {
						Object.keys(address).forEach(key => {
							if (action.address[key]) {
								address[key] = action.address[key];
							}
						})
					}

					if (!address.country && Object.keys(arc.address.countries).length === 1) {
						address.country = Object.keys(arc.address.countries)[0];
					}

					return address;
				})(),
				isBusy: false,
				alerts: false,
				suggestedAddresses: false,
				suggestedCityStatePostalCodeCombinations: false,
				isValidated: false,
				hasSubmitted: false, // Triggers :invalid styles for Safari, since it lack HTML5 validation
			};
		}
		case 'submit': {
			return {
				...state,
				isBusy: true,
				alerts: false,
				suggestedAddresses: false,
				suggestedCityStatePostalCodeCombinations: false,
				isValidated: false,
				hasSubmitted: true,
			}
		}
		case 'feedback': {
			return {
				...state,
				isBusy: false,
				alerts: Boolean(action?.alerts?.length) && action.alerts,
				suggestedAddresses: Boolean(action?.suggestedAddresses?.length) && action.suggestedAddresses,
				suggestedCityStatePostalCodeCombinations: Boolean(action?.suggestedCityStatePostalCodeCombinations?.length) && action.suggestedCityStatePostalCodeCombinations,
			}
		}
		case 'alert': {
			return {
				...state,
				isBusy: false,
				alerts: action.alerts,
			}
		}
		case 'updateAddress': {
			const newAddress = {...state.address, ...action.diff};

			// Reset the value for state if changing the country
			if (!action.diff.state) {
				if (!newAddress.country || (state.address.country !== newAddress.country)) {
					newAddress.state = '';
				}
			}

			return {
				...state,
				address: newAddress,
				alerts: false,
				suggestedAddresses: false,
				suggestedCityStatePostalCodeCombinations: false,
			}
		}
		case 'setCountries': {
			return {
				...state,
				countries: action.countries,
			}
		}
	}
}

export default class extends React.Component<Props> {
	static defaultProps = {
		autoFocus: true,
		submitButtonText: 'Save',
		saveAddress: true,
	};

	dispatch = (action) => {
		this.setState(reducer(this.state, action));
	};

	state = reducer(null, {type: 'init', address: this.props.address});

	componentDidMount() {
		// Get list of states for each country
		Promise.all(Object.keys(arc.address.countries).map(countryCode => {
			return request(arc.path.store, {
				service: 'core',
				action: 'location.State.get',
				params: [{
					filter: {
						countryCode,
						excludeTerritories: arc.address.countries[countryCode].excludeTerritories,
					},
					order: 'a-z',
				}],
			}).then(res => ({
				name: arc.address.countries[countryCode].name,
				code: countryCode,
				states: res.data || [],
			}));
		})).then(values => {
			this.dispatch({
				type: 'setCountries',
				countries: values,
			})
		}).catch(exc);
	}

	handleChange = (e)=>{
		const name = e.currentTarget.name;
		const value = e.currentTarget.value;

		this.dispatch({
			type: 'updateAddress',
			diff: {[name]: value},
		});
	};

	mergeSuggestedAddress = (suggestion)=>{
		this.dispatch({
			type: 'updateAddress',
			diff: {
				city: suggestion.city,
				state: suggestion.state,
				postalCode: suggestion.postalCode,
				country: suggestion.country,
				addressOne: suggestion.addressOne || this.state.address.addressOne,
			},
		});
	};

	save = (shouldValidate = true)=> {
		if (this.props.isShippingAddress) {
			if (POBoxRX.test(this.state.address.addressOne)) {
				this.dispatch({
					type: 'alert',
					alerts: [
						{	
							message: 'We cannot ship to P.O. boxes',
							type: 'error',
						}
					],
				});
				return;
			}

			if (/^apo$/i.test(this.state.address.city.trim())) {
				this.dispatch({
					type: 'alert',
					alerts: [
						{	
							message: 'We cannot ship to APO/FPO addresses',
							type: 'error',
						}
					],
				});
				return;
			}

			if (/^fpo$/i.test(this.state.address.city.trim())) {
				this.dispatch({
					type: 'alert',
					alerts: [
						{	
							message: 'We cannot ship to APO/FPO addresses',
							type: 'error',
						}
					],
				});
				return;
			}
		}

		this.dispatch({
			type: 'submit',
		});

		const address = {...this.state.address};
		const countryCode = address.country;
		const validateAddress = arc.address.countries[countryCode]?.validate;
		const validatePostalCode = arc.address.countries[countryCode]?.validatePostalCode;
		const reqs = [];
		const alerts = [];
		let suggestedAddresses;
		let suggestedCityStatePostalCodeCombinations;

		if (this.props.isShippingAddress && shouldValidate && (validateAddress || validatePostalCode)) {
			if (validateAddress) {
				reqs.push(
					request(arc.path.store,{
						service: 'cart',
						action: 'store.Address.validate',
						params: [address, {
							ignoreDisabledError: true,
						}],
					}).then(res=>{
						res.errors.filter(x => {
							// Code E05P4A3 indicates that there are no suggestions, which is not a helpful message for the user and can be ignored
							return x.code !== 'E05P4A3'
						}).forEach(err => {
							alerts.push({
								type: 'warn',
								message: err.message,
							})
						})

						if (res?.data?.length) {
							suggestedAddresses = res.data;
						}
					})
				);
			}

			if (validatePostalCode) {
				reqs.push(
					request(arc.path.store,{
						service: 'cart',
						action: 'store.Address.validatePostalCode',
						params: [address, {
							ignoreDisabledError: true,
						}],
					}).then(res=>{
						// No need to show both suggested addresses and suggested postal codes
						// todo: ^ is this a true statement
						if (suggestedAddresses) return;

						res.errors.filter(x => {
							// Code E05P4A3 indicates that there are no suggestions, which is not a helpful message for the user and can be ignored
							return x.code !== 'E05P4A3'
						}).forEach(err => {
							alerts.push({
								type: 'warn',
								message: err.message,
							})
						})

						if (res?.data?.length) {
							suggestedCityStatePostalCodeCombinations = res.data;
						}
					})
				);
			}
		}

		return Promise.all(reqs).then(()=>{
			if (alerts.length || suggestedAddresses?.length || suggestedCityStatePostalCodeCombinations?.length) {
				this.dispatch({
					type: 'feedback',
					suggestedAddresses,
					suggestedCityStatePostalCodeCombinations,
					alerts,
				});
				return false;
			} else {
				if (this.props.saveAddress) {
					return request(arc.path.store, {
						service: 'cart',
						action: 'store.Address.update',
						params: [this.props.address ? this.props.address.id : null],
						options: {
							value: address
						}
					}).then(res => {
						if (res.success) {
							this.props.onSuccess(res.data);
							return res.data;
						} else {
							this.dispatch({
								type: 'feedback',
								alerts: [{
									type: 'warn',
									messaage: res.message || 'Something went wrong',
								}],
							});
							return false;
						}
					})
				} else {
					this.props.onSuccess(address);
					return address;
				}
			}
		}).catch(exc);
	}

	render() {
		const addr = this.state.address;
		const country = addr.country && this.state.countries.filter(x => x.code === addr.country)[0];

		return (
			<form className={'AddressForm'+(this.state.hasSubmitted ? ' validate' : '')} ref='form' onSubmit={e=>{
				e.preventDefault();
				e.stopPropagation();
				this.save();
			}}>
				{
					this.props.isShippingAddress && arc.address.shippingAddressNote ? (
						<div className='AddressForm_alert'>
							<Alert type='warn'>{arc.address.shippingAddressNote}</Alert>
						</div>
					) : null
				}
				{
					arc.address.fields.type.enabled && (
						<Select
							placeholder='Address Type'
							name='typeCode'
							required
							value={addr.typeCode}
							text={addr.typeCode === 'residential' && 'Residential' || addr.typeCode === 'business' && 'Business' || ''}
							onChange={this.handleChange}
							autoFocus={this.props.autoFocus}
						>
							<option disabled value=''></option>
							<option value='residential'>Residential</option>
							<option value='business'>Business</option>
						</Select>
					)
				}
				<Input
					placeholder={arc.address.fields.addressee.label}
					name='addressee'
					required
					type='text'
					maxLength={35}
					value={addr.addressee}
					autoComplete='name'
					onChange={this.handleChange}
					autoFocus={this.props.autoFocus && !arc.address.fields.type.enabled} // todo: do better
				/>
				<Input
					placeholder={arc.address.fields.attention.label}
					name='attentionName'
					type='text'
					maxLength={35}
					value={addr.attentionName}
					onChange={this.handleChange}
				/>
				<AddressAutoComplete
					countries={this.state.countries}
					placeholder='Address'
					name='addressOne'
					required
					type='text'
					maxLength={35}
					value={addr.addressOne}
					autoComplete='address-line1'
					onChange={this.handleChange}
					onAutoComplete={diff => {
						this.dispatch({
							type: 'updateAddress',
							diff,
						});
					}}
				/>
				<Input
					placeholder='Apt, Suite, etc.'
					name='addressTwo'
					type='text'
					maxLength={35}
					value={addr.addressTwo}
					autoComplete='address-line2'
					onChange={this.handleChange}
				/>
				<Input
					placeholder='City'
					name='city'
					required
					type='text'
					maxLength={35}
					value={addr.city}
					autoComplete='address-level2'
					onChange={this.handleChange}
				/>
				{
					this.state.countries.length > 1 && (
						<Select
							placeholder='Country'
							name='country'
							autoComplete='country'
							required
							value={addr.country}
							text={country ? country.name : ''}
							onChange={this.handleChange}
						>
							<option disabled value=''></option>
							{
								this.state.countries.map(el => <option key={el.code} value={el.code}>{el.name}</option>)
							}
						</Select>
					)
				}
				<div className='AddressForm_col'>
					<div className='AddressForm_col_state'>
						<Select
							disabled={country && !country.states.length}
							placeholder='State'
							name='state'
							autoComplete='address-level1'
							required
							value={addr.state}
							text={country && country.states.length ? country.states.filter(el => el.code === addr.state).map(el => el.name)[0] || '' : ''}
							onChange={this.handleChange}
						>
							<option disabled value=''></option>
							{
								country && country.states.length ? country.states.map(x => <option key={x.id} value={x.code}>{x.name}</option>) : null
							}
						</Select>
					</div>

					<div className='AddressForm_col_zip'>
						<Input
							placeholder='Zip Code'
							name='postalCode'
							required
							type='tel'
							maxLength={35}
							value={addr.postalCode}
							autoComplete='postal-code'
							onChange={this.handleChange}
						/>
					</div>
				</div>
				<Input
					placeholder='Phone'
					name='phone'
					type='tel'
					maxLength={35}
					value={addr.phone}
					autoComplete='tel-national'
					onChange={this.handleChange}
				/>
				{
					Boolean(this.state.alerts.length) && this.state.alerts.map((x,i)=>(
						<div key={i} className='AddressForm_error'>
							<Alert type={x.type}>{x.message}</Alert>
						</div>
					))
				}
				{this.state.suggestedCityStatePostalCodeCombinations && (
					<div className='AddressForm_error'>
						<Alert type='warn'>
							<div>Suggested locations:</div>
							<div className='AddressForm_suggested'>
							{this.state.suggestedCityStatePostalCodeCombinations.map((x,i)=>{
								const address = {city:x.city, state: x.state, postalCode: x.postalCode};
								return (
									<button type='button' key={i} className='AddressForm_suggested_item' onClick={()=>this.mergeSuggestedAddress(x)}>
										<Address address={address}/>
									</button>
								);
							})}
							</div>
						</Alert>
					</div>
				)}
				{this.state.suggestedAddresses && (
					<div className='AddressForm_error'>
						<Alert type='warn'>
							<div>Suggested addresses:</div>
							<div className='AddressForm_suggested'>
							{this.state.suggestedAddresses.map((x,i)=>(
								<button type='button' key={i} className='AddressForm_suggested_item' onClick={()=>this.mergeSuggestedAddress(x)}>
									<Address address={x}/>
								</button>
							))}
							</div>
						</Alert>
					</div>
				)}
				<div className='AddressForm_edit'>
					{
						this.props.onCancel && <button type='button' onClick={this.props.onCancel}>Cancel</button>
					}
					{
						(this.state.alerts.length && this.state.alerts.every(x => x.type !== 'error')) ? (
							<button
								type='button'
								className='submit-button'
								data-is-busy={this.state.isBusy || null}
								onClick={() => this.save(false)}>
								The address is correct, use as is
							</button>
						) : (
							<button
								type='submit'
								className='submit-button'
								data-is-busy={this.state.isBusy || null}>
								{this.props.submitButtonText}
							</button>
						) 
					}
				</div>
			</form>
		);
	}
}
