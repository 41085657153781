import * as React from "react";
import * as request from 'arcdynamic-request';
import PaginateButtons from '../PaginateButtons';
import Results from '../Results';
import Modal from '../Modal';
import CreditCard from '../CreditCard';
import Alert from '../Alert';
import SaveCreditCard from '../SaveCreditCard';
import exc from '../../exception';
import { useState } from 'react';

const limitCount = 8;

function CardListItem({ card, refresh, edit }) {
	const [isBusy, setIsBusy] = useState(null);

	function purge() {
		if (isBusy) return;

		setIsBusy(true);

		Promise.all([
			request(arc.path.store, {
				service: 'cart',
				action: 'store.customer.Account.purge',
				params: [card.id],
			}).then(() => {
				// todo: error logging
			}),
			refresh(),
		])
		.then(() => {
			setIsBusy(null);
		})
		.catch(e => {
			setIsBusy(null);
			exc(e);
		});
	}

	return (
		<div className='CardList_list_item'>
			<CreditCard card={card}/>
			<div className='CardList_control'>
				<button type='button' className='CardList_control_btn' onClick={edit}>Edit</button>
				<span> | </span>
				<button type='button' className='CardList_control_btn' onClick={purge} data-is-busy={isBusy}>Delete</button>
			</div>
		</div>
	)
}


export default class extends React.Component {
    state = {
		cards: null,
		totalCount: null,
		page: 1,
		edit: false,
		editCard: null,
		errorMessage: null,
	};

    componentDidMount() {
		this.getItems({
			page: 1,
		});
	}

    getItems = (params)=>{
		const filter = {};

		const itemOptions = {
			filter: filter,
			limit: {
				count: limitCount,
				offset: (limitCount * params.page) - limitCount,
			},
			order: params.order || null,
		};

		const countOptions = {
			filter: filter,
			count: true,
		};

		return Promise.all([
			request(arc.path.store, {
				service: 'cart',
				action: 'store.customer.Account.get',
				options: itemOptions,
			}).then((res)=>{
				return res && res.success ? res.data : [];
			}),
			request(arc.path.store, {
				service: 'cart',
				action: 'store.customer.Account.get',
				options: countOptions,
			}).then((res)=>{
				return res && res.success ? res.data : false;
			})
		]).then(values => {
			this.setState({
				cards: values[0],
				totalCount: values[1],
				page: params.page,
			});
		}).catch(exc);
	};

    didUpdate = (newCard)=>{
		// Swap old card object for new card in the existing array.
		const cards = this.state.cards.slice(0);
		cards.splice(cards.indexOf(this.state.editCard), 1, newCard);

		this.setState({
			edit: false,
			editCard: false,
			cards,
		});
	};

    didCreate = ()=>{
		this.getItems({page: this.state.page});
		this.setState({
			edit: false,
			editCard: false,
		});
	};

    render() {
		if (!this.state.cards) return <p>Loading…</p>;

		return (
			<div className='CardList'>
				<div className='CardList_new'>
					<button className='submit-button' onClick={()=> this.setState({edit: true, editCard: null})}>Add payment method</button>
				</div>
				<div className='CardList_list'>
				{
					this.state.cards.map(card => (
						<CardListItem
							key={card.id}
							card={card}
							refresh={()=> this.getItems({page: this.state.page})}
							edit={()=> this.setState({edit: true, editCard: card})}
						/>
					))
				}
				</div>
				<div className='CardList_results'>
					<PaginateButtons onNext={()=>this.getItems({page: this.state.page+1})} onPrev={()=>this.getItems({page: this.state.page-1})} count={limitCount} page={this.state.page} totalCount={this.state.totalCount} itemCount={this.state.cards.length}/>
					<Results count={limitCount} page={this.state.page} totalCount={this.state.totalCount} itemCount={this.state.cards.length}/>
				</div>
				{
					this.state.edit ? (
						<Modal onClose={()=> this.setState({edit: false, editCard: null})}>
							<SaveCreditCard card={this.state.editCard} onSuccess={this.state.editCard ? this.didUpdate : this.didCreate} onCancel={()=> this.setState({edit: false, editCard: null})}/>
							{
								this.state.errorMessage ? (
									<div>
										<Alert type='error'>{this.state.errorMessage}</Alert>
									</div>
								) : null
							}
						</Modal>
					) : null
				}
			</div>
		);
	}
}
