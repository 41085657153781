import * as React from "react";
import LoginPage from '../LoginPage';
import * as request from 'arcdynamic-request';
import exc from '../../exception';

export interface Props {
	user?: any,
}

export default class extends React.Component<Props> {
    state = {
		allowGuest: false,
	};

    componentDidMount() {
		request(arc.path.store, {
			service: 'cart',
			action: 'store.Guest.isEnabled'
		}, {
			expires: 1000*60*60,
		}).then(res => {
			if (res.success) {
				this.setState({allowGuest: res.data});
			}
		}).catch(exc);
	}

    render() {
		const {children, user} = this.props;

		if (user === undefined) {
			return null; // loading
		} else if (user) {
			return children;
		} else {
			return <LoginPage allowGuest={this.state.allowGuest}/>; 
		}
	}
}
