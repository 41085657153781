import * as React from "react";
import CheckoutOrder from '../CheckoutOrder';
import * as request from 'arcdynamic-request';
import CartHeading from '../CartHeading';
import history from '../../history';
import CartContinue from '../CartContinue';
import exc from '../../exception';
import cart from '../../cart';

export interface Props {
	productOrders: any[],
	isCheckoutComplete: boolean,
	user: any,
	location: any,
	cart: any,
}

function giftAlerts(msg) {
	const alerts = [];

	if (msg.length >= arc.giftMessage.characterLimit * 0.85) {
		if (msg.length > arc.giftMessage.characterLimit) {
			alerts.push({
				type: 'error',
				message: "Your message is too long.",
			})
		} else {
			alerts.push({
				type: 'warn',
				message: "Near maximum message length.",
			})
		}
	}

	if (arc.giftMessage.remindToSign) {
		alerts.push({
			type: 'success',
			message: "Don't forget to sign your message!",
		})
	}

	return alerts
}

// State for tracking gift messages and shipping instructions
function initOrderState(orders, prevState) {
	let state;

	orders.forEach(x => {
		if (!prevState[x.orderIndex]) {
			if (!state) {
				state = {...prevState};
			}

			const message = x.message ? x.message.replace(/\r/g, '').replace(/\n/g, '') : '';

			state[x.orderIndex] = {
				message,
				shippingInstructions: x.shippingInstructions || '',
				messageEnabled: true,
				alerts: giftAlerts(message),
			};
		}
	});

	return state || prevState;
}

export default class extends React.Component<Props> {
    state = {
		addresses: null,
		orders: initOrderState(this.props.productOrders, {}), // todo: rerun on componentWillUpdate in case cart changes
	};

    componentDidMount() {
		this.fetchAddresses();
	}

    handleSubmit = (e)=>{
		e.preventDefault();

		const reqs = [];

		this.props.productOrders.forEach(x => {
			const value = {};

			if (this.state.orders[x.orderIndex].messageEnabled) {
				if ((x.message || '') !== this.state.orders[x.orderIndex].message) {
					value['message'] = this.state.orders[x.orderIndex].message;
				}
			} else {
				value['message'] = '';
			}

			if ((x.shippingInstructions || '') !== this.state.orders[x.orderIndex].shippingInstructions) {
				value['shippingInstructions'] = this.state.orders[x.orderIndex].shippingInstructions;
			}

			if (Object.keys(value).length) {
				reqs.push(request(arc.path.store, {
					service: 'cart',
					action: 'store.cart.Order.update',
					params: [x.orderIndex],
					options: {value},
				}));
			}
		})

		if (reqs.length) {
			reqs.push(cart.getCart());
		}

		Promise.all(reqs).then(()=>{
			// todo: handle failure
			history.push(arc.path.cart+'payment');
		}).catch(exc);
	};

    componentWillUpdate(nextProps) {
		if (this.props.user.typeCode !== nextProps.user.typeCode) {
			this.fetchAddresses();
		}
	}

    fetchAddresses = ()=>{
		return request(arc.path.store, {
			service: 'cart',
			action: 'store.Address.get',
			params: [{
				order: 'a-z',
				limit: {
					count: 1000,
				},
			}],
		}).then((res)=>{
			this.setState({
				addresses:res && res.success ? res.data : [],
			})
			return res;
		}).catch(exc);
	};

    purgeAddress = (el)=>{
		return request(arc.path.store, {
			service: 'cart',
			action: 'store.Address.purge',
			params: [el.id],
		}).then((res) => {
			if (res.success) {
				const addresses = this.state.addresses.slice(0);
				addresses.splice(addresses.indexOf(el), 1);
				this.setState({addresses});
			}
			return res;
		}).catch(exc);
	};

    render() {
		const { productOrders, isCheckoutComplete, user, location, cart } = this.props;

		const meetsGiftMessageRequirements = arc.giftMessage.isRequired ? productOrders.every(x => !this.state.orders[x.orderIndex].messageEnabled || (this.state.orders[x.orderIndex].messageEnabled) && this.state.orders[x.orderIndex].message) : true;
		const giftMessageHasError = productOrders.some(x => this.state.orders[x.orderIndex].messageEnabled && this.state.orders[x.orderIndex].alerts.find(x => x.type === 'error'));
		
		let errorMessage = null;

		if (!isCheckoutComplete) {
			if (productOrders.length > 1) {
				errorMessage = 'To continue, each order needs a shipping address and shipping method.';
			} else {
				errorMessage = 'To continue, choose a shipping address and shipping method.';
			}
		} else if (!meetsGiftMessageRequirements) {
			if (productOrders.length > 1) {
				errorMessage = 'Add a gift message to each order or uncheck to proceed without one.';
			} else {
				errorMessage = 'Add a gift message or uncheck to proceed without one.';
			}
		} else if (giftMessageHasError) {
			errorMessage = 'Fix gift message error to proceed.';
		}

		return (
			<div className='Checkout'>
				<CartHeading heading='Shipping' user={user} location={location}/>
				{
					productOrders.map(el => (
						<CheckoutOrder 
							key={el.orderIndex} 
							title={productOrders.length > 1 ? (el.name ? 'Order for '+el.name : arc.orderName) : null} 
							cart={cart} 
							order={el} 
							isGuest={user.typeCode === 'guest'} 
							addresses={this.state.addresses} 
							fetchAddresses={this.fetchAddresses} 
							purgeAddress={this.purgeAddress}
							message={this.state.orders[el.orderIndex].message}
							alerts={this.state.orders[el.orderIndex].alerts}
							setMessage={val =>{
								this.setState((state: any) => {
									const orders = {
										...state.orders, 
										[el.orderIndex]: {
											...state.orders[el.orderIndex], 
											message: val.replace(/\r/g, '').replace(/\n/g, ''),
											alerts: giftAlerts(val),
										}
									};

									return {
										...state,
										orders,
									}
								});
							}}
							messageEnabled={this.state.orders[el.orderIndex].messageEnabled}
							setMessageEnabled={val => {
								this.setState((state: any) => {
									const orders = {
										...state.orders,
										[el.orderIndex]: {...state.orders[el.orderIndex], 
										messageEnabled: val,
									}};

									return {
										...state,
										orders,
									}
								})
							}}
							shippingInstructions={this.state.orders[el.orderIndex].shippingInstructions}
							setShippingInstructions={val => {
								const orders = {...this.state.orders, [el.orderIndex]: {...this.state.orders[el.orderIndex], shippingInstructions: val}};
								this.setState({orders});
							}}
						/>
					))
				}
				<form onSubmit={this.handleSubmit}>
					<CartContinue disabled={!!errorMessage} title='Continue to payment'>{errorMessage}</CartContinue>
				</form>
			</div>
		);
	}
}
