import * as React from "react";
import ApplyDiscount from '../ApplyDiscount';
import CartSummary from '../CartSummary';
import Branding from '../Branding';

export interface Props {
	productOrders?: any[],
	cart: any,
	children: any,
	isCheckoutComplete: boolean,
}

export default ({children, cart, productOrders, isCheckoutComplete}: Props)=> (
	<div className='CartColumn'>
		<div className='CartColumn_main'>
			<div className='CartColumn_main_body'>
				{children}
			</div>
		</div>
		<div className='CartColumn_side'>
			<div className='CartColumn_side_body'>
				<Branding src={arc.path.logo} desc={arc.desc}/>
				{
					productOrders && productOrders.length ? (
						<div style={{position: 'sticky', top: 0}}>
							<ApplyDiscount/>
							<CartSummary isCheckoutComplete={isCheckoutComplete} cart={cart} productOrders={productOrders}/>
						</div>
					) : null
				}
			</div>
		</div>
	</div>
);