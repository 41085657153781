import * as React from "react";
import currency from '../../currency';
import * as resize from 'arcdynamic-resize';

export interface Props {
	product?: any,
}

const thumbSize = 94;

export default class extends React.Component<Props> {
    render() {
		const { product } = this.props;
		const href = arc.path.product+product.sku;
		const thumbUrl = product.files.find(el => el.type === 'image')?.url;
		const src = thumbUrl ? resize(thumbUrl, thumbSize, thumbSize) : null;

		return (
			<div className='OrderDetailProduct'>
				<a href={href} className='OrderDetailProduct_img'>
					<img src={src} width={thumbSize}/>
				</a>
				<div className='OrderDetailProduct_body'>
					<div><a href={href}>{product.name}</a> ({product.quantity})</div>
					{
						product.fixedAttributeValues ? (
							product.fixedAttributeValues.map(x=>(
								<div key={product.__type==='subscription.Product' ? x.id : x.attributeValueId} className='OrderDetailProduct_body_attribute'>
									{x.attributeName}: {x.name}
								</div>
							))
						) : null
					}
					{
						product.optionalAttributeValues ? (
							product.optionalAttributeValues.map(x=>(
								<div key={x.attributeValueId} className='OrderDetailProduct_body_attribute'>
									{x.attributeName}: {x.name}
								</div>
							))
						) : null
					}
					{
						product.subscriptionPeriod ? (
							<div className='OrderDetailProduct_body_subscription'>
								Subscription: {product.subscriptionPeriod.name}
							</div>
						) : null
					}
					{
						<div>
							{currency(product.price * product.quantity)}
						</div>
					}
				</div>
			</div>
		);
	}
}
