import * as React from "react";
import * as svgGift from '../../svgs/gift.svg';
import Icon from '../Icon';
import Alert from '../Alert';

export interface Props {
	value?: string,
	setMessage: any,
	messageEnabled: boolean,
	setMessageEnabled: any,
	alerts: any[],
}

export default class extends React.Component<Props> {
	state = {
		focus: false,
	}

	render() {
		const { value, setMessage, messageEnabled, setMessageEnabled, alerts } = this.props;

		return (
			<div className='OrderGiftMessage'>
				<label className='OrderGiftMessage_label'>
					{arc.giftMessage.isRequired && <input type='checkbox' checked={messageEnabled} onChange={e => {
						setMessageEnabled(e.currentTarget.checked)
					}}/>}
					{arc.giftMessage.showIcon && <span className={'OrderGiftMessage_icon'}><Icon svg={svgGift}/></span>}
					{arc.giftMessage.label}
				</label>
				{
					messageEnabled && (
						<div>
							<textarea 
								value={value} 
								onFocus={()=> this.setState({focus: true})} 
								onBlur={()=> this.setState({focus: false})} 
								onChange={e => setMessage(e.currentTarget.value)} // using onChange instead of onInput to avoid React warning. If React didn't change how these events function, then onInput would be the correct choice here because we want to update the status as the user types and not wait until after focus is lost.
								placeholder='Optional message displays on packing slip'
							/>
							{
								alerts.map(({type, message}, i)=> (
									<div key={i} className='OrderGiftMessage_alert' style={{opacity: type === 'error' || this.state.focus ? 1 : 0}}>
										<Alert type={type}>{message}</Alert>
									</div>
								))
							}
						</div>
					)
				}
			</div>
		);
	}
}

