import './exception';
import { createElement } from 'react';
import * as ReactDOM from 'react-dom';
import routes from './routes';
import history from './history';
import * as localLink from 'local-link';
import store from './store';
import * as Router from 'bare-router';
import { updateLocation } from './actions';
import auth from './auth';
import cart from './cart';
import configDefaults from './config.defaults'
import * as merge from 'deepmerge';

window['arc'] = merge(configDefaults, window['arc']);

const router = Router(routes);

// Render Cycle
{
	const container = document.createElement('div');
	container.id = 'root';
	document.body.appendChild(container);

	Promise.all([
		auth.getUser(),
		cart.getCart(),
	]).then(values => {
		store.subscribe((state) => {
			const { handler, params } = router(state.location.pathname);

			// not used anywhere, just for inspection
			window['arc'].state = state;

			if (handler) {
				const component = createElement(handler, {params, props: state});
				ReactDOM.render(component, container);
			} else {
				container.textContent = 'Route not found';
			}
		})

		// Setting the location will update the store, starting the render cycle
		updateLocation(window.location);
	})
}

// SPA Navigation
{
	// Intercept anchor clicks
	window.addEventListener('click', (e) => {
		const anchor = localLink(e);

		if (!anchor) return;

		const { handler } = router(anchor.pathname);

		if (handler) {
			e.preventDefault();
			history.push(anchor.href);
			window.scrollTo(0, 0);
		}
	});

	// Catch back/forward buttons
	window.addEventListener('popstate', ()=>{
		updateLocation(window.location);
	});
}

// Refresh user and cart when tab is focused, as they
// might have timed out or been modified in a different tab
document.addEventListener('visibilitychange', ()=>{
	if (!document.hidden) {
		auth.getUser();
		cart.getCart();
	}
});

// Fetch the user and cart and refresh periodically
setInterval(()=>{
	if (!document.hidden) {
		auth.getUser();
		cart.getCart();
	}
}, 60000);