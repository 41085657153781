import * as React from "react";
import Authenticate from '../Authenticate';

export interface Props {
	allowGuest: boolean,
	onSuccess?: any,
}

export default ({allowGuest, onSuccess}: Props)=> (
	<div className='LoginPage'>
		<div className='LoginPage_body'>
			<Authenticate title='Sign in to continue' allowGuest={allowGuest} onSuccess={onSuccess}/>
		</div>
	</div>
);