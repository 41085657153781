import * as React from "react";
import currency from '../../currency';

export interface Props {
	purchase: any,
}

export default ({purchase}: Props)=> (
	<div className='CartPurchase'>
		<hr/>
		<div className='CartPurchase_line'>
			<span className='CartPurchase_label'>Subtotal</span>
			{currency(parseFloat(purchase.subTotal) + parseFloat(purchase.subscriptionAmount))}
		</div>
		<div className='CartPurchase_line'>
			<span className='CartPurchase_label'>Shipping</span>
			{currency(purchase.shippingAmount)}
		</div>
		{
			(purchase.totalDiscountShippingAmount) ? (
				<div className='CartPurchase_line'>
					<span className='CartPurchase_label'>Shipping discount</span>
					-{currency(purchase.totalDiscountShippingAmount)}
				</div>
			) : null
		}
		{
			(purchase.totalDiscountCurrencyAmount + purchase.totalDiscountPercentAmount) ? (
				<div className='CartPurchase_line'>
					<span className='CartPurchase_label'>Discounts</span>
					-{currency(purchase.totalDiscountCurrencyAmount + purchase.totalDiscountPercentAmount)}
				</div>
			) : null
		}
		<div className='CartPurchase_line'>
			<span className='CartPurchase_label'>Tax</span>
			{currency(purchase.taxAmount)}
		</div>
		<hr/>
		<div className='CartPurchase_line'>
			<span className='CartPurchase_label'><strong>Purchase Total</strong></span> <span className='CartPurchase_total'>
				{currency(purchase.total)}
			</span>
		</div>
	</div>
);