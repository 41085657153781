import * as React from "react";
import LoginPage from '../LoginPage';

export default ({children, user}: {children: any, user: any})=> {
	if (user == null) {
		return null; // loading
	} else if (user && user.typeCode !== 'guest') {
		return children;
	} else {
		return <LoginPage allowGuest={false}/>; 
	}
};