import * as React from "react";
import Alert from '../Alert';
import auth from '../../auth';
import exc from '../../exception';

export interface Props {
	onSuccess?: any,
	title?: string,
	allowGuest: boolean,
	newAccount?: boolean,
	resetPassword: any,
	register?: any,
}

export default class extends React.Component<Props> {
    state = {
		errorMessage: null,
		guest: false,
		isBusy: null,
	};

	private email;
	private password;

    private handleSubmit = (e)=>{
		e.preventDefault();

		this.setState({isBusy: true});
		
		if (!this.state.guest) {
			const username = this.email.value;
			const password = this.password.value;
	
			auth.login(username, password).then(res => {
				this.setState({isBusy: null});
	
				if (res && res.success) {
					if (this.props.onSuccess) {
						this.props.onSuccess();
					}
				} else {
					this.setState({errorMessage: res && res.message ? res.message : "There was a problem logging in"});
				}
			}).catch(exc)
		} else {
			const email = this.email.value;
			
			auth.guestLogin(email).then(res => {
				if (res && res.success) {
					if (this.props.onSuccess) {
						this.props.onSuccess();
					}
				} else {
					this.setState({errorMessage: res && res.message ? res.message : "There was a problem with guest checkout"});
				}
			}).catch(exc)
		}
	};

    render() {
		return (
			<div className='Login'>
				{this.props.title ? <h1>{this.props.title}</h1> : null}
				<form ref='form' onSubmit={this.handleSubmit}>
					<div className='Login_field'>
						<input type='email' name='email' ref={x => this.email = x} required autoFocus placeholder='Email' autoComplete='username'/>
					</div>
					{!this.state.guest?
						<div className='Login_field'>
							<input type='password' ref={x => this.password = x} required placeholder='Password' autoComplete='current-password'/>
						</div>
					:null}
					<div className='Login_misc'>
						{
							this.props.allowGuest ? (
								<label className='Login_remember' onMouseDown={(e)=>e.preventDefault()} onMouseUp={(e)=>e.preventDefault()} onClick={(e)=> {
									// Preventing default on mousedown, mouseup, and click to prevent taking focus from the email input
									e.preventDefault();
									this.setState({guest: !this.state.guest});
								}}>
									<input type='checkbox' ref='guestCheckout' checked={this.state.guest} onChange={(e)=> this.setState({guest: e.currentTarget.checked})} onMouseDown={(e)=>e.preventDefault()} onMouseUp={(e)=>e.preventDefault()} onClick={(e)=> e.stopPropagation()}/> Continue as guest
								</label>
							) : null
						}
						{!this.state.guest?<button type='button' onClick={this.props.resetPassword} className='Login_reset'>Forgot password?</button>:null}
					</div>
					<button type='submit' data-is-busy={this.state.isBusy} className='Login_btn Login_btn--old'>{!this.state.guest?'Sign In':'Continue'}</button>
				</form>
				{!this.props.newAccount && !this.state.guest ? <button onClick={this.props.register} className='Login_btn Login_btn--new'>Create New Account</button> : null}
				{
					this.state.errorMessage ? (
						<div className='Login_error'>
							<Alert type='error'>{this.state.errorMessage}</Alert>
						</div>
					) : null
				}
			</div>
		);
	}
}