import * as request from 'arcdynamic-request';
import { setCart } from './actions'
import exc from './exception';

export default {
	getCart() {
		return request(arc.path.store, {
			service: 'cart',
			action: 'store.Cart.get',
		}).then((res)=>{
			setCart(res.data);
			return res;
		}).catch(exc)
	},
	removeProduct({orderIndex, id}) {
		return request(arc.path.store, {
			service: 'cart',
			action: 'store.Cart.removeProduct',
			params: [orderIndex || null, id, true],
		}).then((res)=>{
			if (res && res.success) {
				setCart(res.data);
			}
			return res;
		}).catch(exc);
	},
	updateProductQuantity({orderIndex, id, quantity, productIndex}) {
		if (quantity === 0) {
			return this.removeProduct({orderIndex, id});
		} else {
			return request(arc.path.store, {
				service: 'cart',
				action: 'store.Cart.updateProductQuantity',
				params: [orderIndex || null, id, quantity, productIndex],
			}).then((res)=>{
				if (res && res.success) {
					setCart(res.data);
				}
				return res;
			}).catch(exc);
		}
	},
	removeSubscription({orderIndex, id}) {
		return request(arc.path.store, {
			service: 'cart',
			action: 'store.Cart.subscription.removeProduct',
			params: [orderIndex || null, id, true],
		}).then((res)=>{
			if (res && res.success) {
				setCart(res.data);
			}
			return res;
		}).catch(exc);
	},
	updateSubscription({orderIndex, id, quantity, subscriptionPeriodId}) {
		if (quantity === 0) {
			return this.removeSubscription({orderIndex, id});
		} else {
			return request(arc.path.store, {
				service: 'cart',
				action: 'store.Cart.subscription.updateProduct',
				params: [orderIndex || null, id, {
					value: {
						quantity,
						subscriptionPeriodId,
					},
				}],
			}).then((res)=>{
				if (res && res.success) {
					setCart(res.data);
				}
				return res;
			}).catch(exc);
		}
	},
};