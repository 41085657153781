import * as React from "react";
import getCheapestShippingMethod from '../../getCheapestShippingMethod';
import CompactRadio from '../CompactRadio';
import currency from '../../currency';
import months from '../../months';
import DeliveryCalendar from '../DeliveryCalendar';

const days = 'Sun Mon Tues Wed Thurs Fri Sat'.split(' ');

function formatDate(date) {
	return `${days[date.getUTCDay()]}, ${months[date.getUTCMonth()]} ${date.getUTCDate()}`;
}

function getChoices(shipping) {
	const unique = {};

	Object.keys(shipping.calendar).sort().forEach(key => {
		const method = getCheapestShippingMethod(shipping.methods, shipping.calendar[key]);

		if (method && !unique[method.code]) {
			unique[method.code] = {
				deliveryDate: key,
				method,
			};
		}
	});

	const uniqueChoices = Object.keys(unique).map(key => unique[key]).sort((a,b)=> a.method.amount - b.method.amount);

	return uniqueChoices.filter((el,i) => {
		return i === 0 || (el.deliveryDate < uniqueChoices[0].deliveryDate);
	})
}

export interface Props {
	setMethod: any,
	shipping: any,
	isBusy: boolean,
	deliveryDate?: string,
	shippingMethodId?: number,
}

export default class extends React.Component<Props> {
    state = {
        edit: false,
        customDate: false,
    };

    earlyMorning = () => {
		const { deliveryDate, setMethod, shippingMethodId, shipping } = this.props;

		if (deliveryDate && shipping.calendar !== null) {
			const availableMethods = shipping.calendar[deliveryDate];
			const cheapestMethod = getCheapestShippingMethod(shipping.methods, availableMethods);
			const earlyMorningAvailable = availableMethods && availableMethods.indexOf(2) !== -1; // 2 is the ID for early morning shipping.  todo: get this out of UI
			const earlyMorningMethod = shipping.methods.filter(el => el.id === 2).pop();

			if (earlyMorningAvailable && earlyMorningMethod) {
				return (
					<div className='SimpleDelivery_early'>
						<CompactRadio>
							<input type='checkbox' checked={shippingMethodId === 2} onChange={()=> setMethod(shippingMethodId === 2 ? cheapestMethod.id : 2, deliveryDate)} />
							Guarantee 10:30 a.m. delivery <span className='SimpleDelivery_price'> — {currency(earlyMorningMethod.amount)}</span>
						</CompactRadio>
					</div>
				);
			}
		} else {
			return null;
		}
	};

    render() {
		const { shipping, setMethod, shippingMethodId, isBusy } = this.props;
		const choices = shipping.calendar !== null ? getChoices(shipping) : null;
		const deliveryDate = this.props.deliveryDate ? this.props.deliveryDate.slice(0,10) : null;
		let predefinedSelected = false;

		return (
			<form onSubmit={e => e.preventDefault()} className='SimpleDelivery'>
				{
					choices ? (
						<div>
							{
								choices.map(el => {
									const isSelected = deliveryDate === el.deliveryDate; // todo: brittle convention, inferred

									if (isSelected) {
										predefinedSelected = true;
									}

									return (
										<CompactRadio key={el.method.code}>
											<input required name='delivery-date' type='radio' defaultChecked={isSelected} value={el.method.code} onChange={()=>{
												this.setState({
													edit: false,
													customDate: false,
												});
												setMethod(el.method.id, el.deliveryDate);
											}}/>
											<div>{el.method.name} <span className='SimpleDelivery_price'> — {formatDate(new Date(el.deliveryDate))}</span></div>
										</CompactRadio>
									);
								})
							}
							<CompactRadio>
								<input required name='delivery-date' type='radio' value='' onChange={()=> this.setState({edit: true})} defaultChecked={deliveryDate && shippingMethodId && !predefinedSelected}/>
								<div>Custom date
									{
										((deliveryDate && shippingMethodId && !predefinedSelected) || this.state.customDate) ? <span className='SimpleDelivery_price'> — {formatDate(new Date(deliveryDate))}</span> : null
									}
								</div>
							</CompactRadio>
						</div>
					) : shipping.methods.map(el => {
						return (
							<div key={el.id} className='SimpleDelivery_early'>
								<CompactRadio>
									<input type='checkbox' checked={shippingMethodId === el.id} onChange={()=> setMethod(el.id, null)} />
									<span>{el.name} – <span className='SimpleDelivery_price'>{currency(el.amount)}</span></span>
								</CompactRadio>
							</div>
						);
					})
				}
				{
					this.state.edit ? (
						<div data-is-busy={isBusy}>
							<DeliveryCalendar setMethod={(id, date)=> {
								this.setState({
									edit: false,
									customDate: true,
								});
								setMethod(id, date);
							}} shipping={shipping} deliveryDate={deliveryDate}/>
						</div>
					) : null
				}
				{this.earlyMorning()}
			</form>
		);
	}
}
