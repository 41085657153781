import * as React from "react";
import Alert from '../Alert';
import * as request from 'arcdynamic-request';
import cart from '../../cart';
import exc from '../../exception';
import Modal from '../Modal';
import SimpleDelivery from '../SimpleDelivery';

export interface Props {
	order: any,
}

export default class extends React.Component<Props> {
    state = {
		shipping: null,
		methods: null,
		error: null,
		showPopup: false,
		isBusy: null,
	};

    getShipping = (order)=>{
    	request(arc.path.store, {
    		service: 'cart',
    		action: 'store.cart.Order.getShippingMethodGroup',
    		params: [order.orderIndex],
    	})
		.then(res =>{
			const shipping = res.success ? {...res.data} : null;

			// If only one shipping method is available, go ahead and select it
			if (shipping && Array.isArray(shipping.methods) && shipping.methods.length === 1) {
				this.setMethod(shipping.methods[0].id).then(() => {
					// Don't bother showing shipping options unless there is a calendar
					if (shipping.calendar) {
						this.setState({shipping});
					}
				})
			} else {
				this.setState({shipping});
			}

		}).catch(exc);
	};

    componentDidMount() {
		this.getShipping(this.props.order);
	}

    componentWillReceiveProps(nextProps) {
		if (nextProps.order.shippingAddressId !== this.props.order.shippingAddressId) {
			this.getShipping(nextProps.order);
		}
	}

    setMethod = (shippingMethodId, deliveryDate = null)=>{
    	return new Promise((resolve, reject)=> {
			if (this.state.isBusy) {
				reject();
			}

			this.setState({isBusy: true});
			
			resolve(Promise.all([
				request(arc.path.store, {
					service: 'cart',
					action: 'store.cart.Order.update',
					params: [this.props.order.orderIndex],
					options: {
						value: {
							shippingMethodId,
							deliveryDate,
						}
					},
				}),
				cart.getCart(),
			]).then(values => {
				const update = values[0];
				if (update && update.success) {
					this.setState({
						isBusy: null,
					});
				} else {
					this.setState({
						error: update.message ? update.message : 'There was an error selecting the delivery date, the cut-off time for this date may have passed.',
						isBusy: null,
					});
				}
				return update;
			}).catch(exc));
    	})
	};

    render() {
		if (!this.state.shipping) return null;

		const order = this.props.order;

		return (
			<div className='CheckoutDelivery'>
				{
					this.state.showPopup ? (
						<Modal onClose={()=> this.setState({showPopup: false})}>
							<div className='CheckoutDelivery_popup' dangerouslySetInnerHTML={{__html: arc.shippingInfo}}/>
						</Modal>
					) : null
				}
				<h2>Shipping Method</h2>
				{
					arc.shippingInfo ? (
						<div>
							<button type='button' className='CheckoutDelivery_link' onClick={()=> this.setState({showPopup: true})}>
								See shipping information.
							</button>
						</div>
					) : null 
				}
				<SimpleDelivery
					isBusy={this.state.isBusy}
					setMethod={this.setMethod}
					shipping={this.state.shipping}
					deliveryDate={order.deliveryDate ? order.deliveryDate.slice(0,10) : null}
					shippingMethodId={order.shippingMethodId}
				/>
				{
					this.state.error ? <Alert type='error'>{this.state.error}</Alert> : null
				}
			</div>
		);
	}
}
