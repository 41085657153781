import * as React from "react";

const Input = React.forwardRef((props: any, ref = null) => {
	const [hasFocus, setHasFocus] = React.useState(false);
	const {value = '', placeholder = '', onFocus, onBlur, ...rest} = props;

	return (
		<label className={'Input'+(value ? ' Input--fill' : '')+(hasFocus ? ' Input--focus' : '')}>
			<div className='Input_label'>{placeholder}</div>
			<input ref={ref} onFocus={(e)=> {
				setHasFocus(true)
				if (typeof onFocus === 'function') {
					onFocus(e);
				}
			}} onBlur={(e)=> {
				setHasFocus(false)
				if (typeof onBlur === 'function') {
					onBlur(e);
				}
			}} value={value} placeholder={placeholder} {...rest}/>
		</label>
	);
})

export default Input;