import * as React from "react";
import Modal from '../Modal';
import Authenticate from '../Authenticate';
import Icon from '../Icon';
import * as svgShoppingCart from '../../svgs/shopping-cart.svg';
import * as svgPerson from '../../svgs/person.svg';

export interface Props {
	user?: any,
	productCount?: any,
}

export default class extends React.Component<Props> {
    state = {
        isLoginOpen: false,
    };

    userBtn = () => {
		if (this.props.user == null) return null;

		if (this.props.user && this.props.user.typeCode !== 'guest') {
			return (
				<a className='CartHeader_link' href={arc.path.cart+'account'}>
					<span className='CartHeader_icon'><Icon svg={svgPerson}/></span> <span className='CartHeader_label CartHeader_compact'>Your Account</span>
				</a>
			)
		} else {
			return (
				<a className='CartHeader_link' href={arc.path.cart+'login'} onClick={(e)=> { e.preventDefault(); this.setState({isLoginOpen: true}); }}>
					<span className='CartHeader_icon'><Icon svg={svgPerson}/></span> <span className='CartHeader_label CartHeader_compact'>Login</span>
				</a>
			);
		}
	};

    cartBtn = () => {
		return this.props.productCount === undefined ? null : (
			<a className='CartHeader_link' href={arc.path.cart}>
				<span className='CartHeader_icon'><Icon svg={svgShoppingCart}/></span> <span className='CartHeader_label'>{this.props.productCount}</span>
			</a>
		);
	};

    render() {
		return (
			<div className='CartHeader'>
				<div className='CartHeader_body'>
					<a className='CartHeader_link' href={arc.path.return || arc.path.base}><span className='CartHeader_back' aria-hidden={true}/> <span className='CartHeader_label'>Continue shopping</span></a>
					{this.userBtn()}
					{this.cartBtn()}
				</div>
				{
					this.state.isLoginOpen ? (
						<Modal onClose={()=> this.setState({isLoginOpen: false})}>
							<Authenticate title='Sign in' allowGuest={false} onSuccess={()=> this.setState({isLoginOpen: false})}/>
						</Modal>
					) : null
				}
			</div>
		);
	}
}
