import * as React from "react";
import Branding from '../Branding';
import OrderDetail from '../OrderDetail';
import CartPurchase from '../CartPurchase';
import * as request from 'arcdynamic-request';
import exc from '../../exception';

export interface Props {
	id: string,
}

export default class extends React.Component<Props> {
	state = {
		purchase: null,
	};

    componentDidMount() {
		request(arc.path.store, {
			service: 'cart',
			action: 'store.Purchase.get',
			params: [this.props.id],
		}).then((res)=>{
			this.setState({
				purchase: res && res.success && res.data ? res.data : false,
			})
		}).catch(exc);
	}

    render() {
		const purchase = this.state.purchase;
		if (purchase === null) {
			return null;
		}
		return (
			<div className='CartColumn'>
				<div className='CartColumn_main'>
					<div className='CartColumn_main_body'>
						{
							purchase ? (
								<div>
									<h1>Thank you for your purchase!</h1>
									{
										purchase.orders.map(el => <OrderDetail hideSummary={true} key={el.id} order={el}/>)
									}
								</div>
							) : (
								<div>
									<h1>Page Expired</h1>
									<h2>Review previous orders on the <a href={arc.path.cart+'account/order-history'}>order history</a> page.</h2>
								</div>
							)
						}
					</div>
				</div>
				<div className='CartColumn_side'>
					<div className='CartColumn_side_body'>
						<Branding src={arc.path.logo} desc={arc.desc}/>
						{purchase ? <CartPurchase purchase={purchase}/> : null}
					</div>
				</div>
			</div>
		);
	}
}
