import * as React from "react";
import currency from '../../currency';
import CartSummaryItem from '../CartSummaryItem';

export default ({cart, productOrders, isCheckoutComplete}: {cart: any, productOrders: any[], isCheckoutComplete: boolean})=> (
	<div className='CartSummary'>
		<div className='CartSummary_orders'>
			{
				productOrders.map(el => <CartSummaryItem key={el.orderIndex} order={el} showShipping={productOrders.length > 1}/>)
			}
		</div>
		<div className='CartSummary_line'>
			<span className='CartSummary_label'>Subtotal</span> {currency(cart.subTotal + cart.subscriptionAmount)}
		</div>
		<hr/>
		{
			(cart.totalDiscountCurrencyAmount + cart.totalDiscountPercentAmount) ? (
				<div className='CartSummary_line'>
					<span className='CartSummary_label'>Discounts</span>
					-{currency(cart.totalDiscountCurrencyAmount + cart.totalDiscountPercentAmount)}
				</div>
			) : null
		}
		<div className='CartSummary_line'>
			<span className='CartSummary_label'>Shipping</span>
			{
				isCheckoutComplete ? currency(cart.shippingAmount) : '--'
			}
		</div>
		{
			cart.totalDiscountShippingAmount ? (
				<div className='CartSummary_line'>
					<span className='CartSummary_label'>Shipping discount</span>
					-{currency(cart.totalDiscountShippingAmount)}
				</div>
			) : null
		}
		{
			cart.taxAmount ? (
				<div className='CartSummary_line'>
					<span className='CartSummary_label'>Tax</span>
					{
						isCheckoutComplete ? currency(cart.taxAmount) : '--'
					}
				</div>
			) : null
		}
		{
			cart.giftcardAmount ? (
				<div className='CartSummary_line'>
					<span className='CartSummary_label'>Giftcards</span> -{currency(cart.giftcardAmount)}
				</div>
			) : null
		}
		<hr/>
		<div className='CartSummary_line'>
			<span className='CartSummary_label'><strong>Purchase Total</strong></span> <span className='CartSummary_total'>
				{
					isCheckoutComplete ? currency(cart.total - (cart.giftcardAmount?cart.giftcardAmount:0)) : '--'
				}
			</span>
		</div>
		{
			arc.aside.message && <div className='CartSummary_message' dangerouslySetInnerHTML={{__html: arc.aside.message}}/>
		}
	</div>
);