import * as React from "react";
import cart from '../../cart';
import currency from '../../currency';
import CartProduct from '../CartProduct';
import exc from '../../exception';
import Alert from '../Alert';

export interface Props {
	product: any,
	orderIndex: number,
}

const OrderRow = class extends React.Component<Props> {
	state = {
		isBusy: null,
		error: false,
		quantity: this.props.product.quantity,
	};

    removeProduct = (id, subscriptionPeriodId)=>{
		this.setState({isBusy: true});

		let request = subscriptionPeriodId ? cart.removeSubscription({id, orderIndex: this.props.orderIndex}) : cart.removeProduct({id, orderIndex: this.props.orderIndex});

		request.then(res =>{
			this.setState({isBusy: null});
		}).catch(exc);
	};

    _handleChange = (e)=>{
		let quantity = e.target.value;

		if (quantity !== '') {
			quantity = Number(quantity);

			if (isNaN(Number(quantity)) || quantity < 0) {
				return;
			}
		}

		this.setState({
			error: false,
			quantity,
		});
	};

    _submit = (e)=>{
		e.preventDefault();
	};

    _blur = ()=>{
		const { quantity } = this.state;
		const { product } = this.props;

		if (quantity === product.quantity) {
			return;
		}

		if (quantity === '') {
			this.setState({quantity: product.quantity});
			return;
		}

		this.setState({isBusy: true});

		const { orderIndex, productIndex, subscriptionPeriodId } = product;
		const id = product.productId;

		const request = subscriptionPeriodId ? 
			cart.updateSubscription({orderIndex, id, quantity, subscriptionPeriodId}) : 
			cart.updateProductQuantity({orderIndex, id, quantity, productIndex});

		request.then(res => {
			this.setState({
				isBusy: null,
				error: res.success ? false : res.message,
			});
		}).catch(exc);
	};

    componentWillReceiveProps(nextProps) {
		this.setState({quantity: nextProps.product.quantity});
	}

    render() {
		const { product } = this.props;

		let discountAmount = 0;
		let discountAmountPerProduct = 0;
		if(product.discounts) {
			product.discounts && product.discounts.forEach(x=>{
				discountAmount += x.currencyAmount + x.percentAmount + x.shippingAmount;
			});
			discountAmountPerProduct = discountAmount / product.quantity;
		}

		const subTotal = (product.subscriptionPeriod ? product.subscriptionPeriod.price : product.price) * product.quantity;

		return (
			<div className={'Order_item'+(this.state.error ? ' Order_item--error' : '')} data-disabled={this.state.isBusy}>
				<div className='Order_item_body'>
					<div className='Order_item_product'>
						<CartProduct orderProduct={product} discountAmount={discountAmountPerProduct}/>
					</div>
					<div className='Order_item_quantity'>
						<form onSubmit={this._submit}>
							<input
								type='number' 
								min='1' 
								max={product.limitPerOrder ||  null}
								value={this.state.quantity} 
								onChange={this._handleChange}
								required
								onBlur={this._blur}
								aria-label='quantity'
							/>
							{
								product.limitPerOrder ? (
									<div className='Order_quantity_limit'>
										Limit {product.limitPerOrder}
									</div>
								) : null
							}
						</form>
					</div>
					<div className='Order_item_subtotal'>
						<span className={discountAmount ? 'Order_item_subtotal_discount' : ''}>{currency(subTotal)}</span>
						{discountAmount ? <div>{currency(subTotal - discountAmount)}</div> : null}
					</div>
					<div className='Order_item_remove'>
						<button type='button' className='Order_item_remove_btn' onClick={()=> this.removeProduct(product.productId, product.subscriptionPeriodId)} aria-label='remove product from cart'>×</button>
					</div>
				</div>
				{
					this.state.error ? (
						<div className='Order_item_error'>
							<Alert type='error'>{this.state.error}</Alert>
						</div>
					) : null
				}
			</div>
		);
	}
}

export default ({order, name}: {order: any, name: string})=> (
	<div className='Order'>
		<div className='Order_head'>
			<div className='Order_head_product'>{name}</div>
			<div className='Order_head_quantity'>Quantity</div>
			<div className='Order_head_subtotal'>Subtotal</div>
			<div className='Order_head_remove'></div>
		</div>
		<div className='Order_body'>
			{
				order.products.map(product => (
					<OrderRow key={product.productId+(product.subscriptionPeriodId ? '-'+product.subscriptionPeriodId : '')} product={product} orderIndex={order.orderIndex}/>
				))
			}
		</div>
	</div>
);
