import * as React from "react";
import history from '../../history';
import * as request from 'arcdynamic-request';
import CartHeading from '../CartHeading';
import Alert from '../Alert';
import GiftCard from '../GiftCard';
import CreditCardForm from '../CreditCardForm';
import FormContainer from '../FormContainer';
import FormRadio from '../FormRadio';
import FormSection from '../FormSection';
import CartContinue from '../CartContinue';
import CreditCardBar from '../CreditCardBar';
import exc from '../../exception';
import { setPurchase } from '../../actions';

export interface CProps {
	cart: any,
	user: any,
	location: any,
	productOrders: any[],
}

const Component = class extends React.Component<CProps> {
    componentDidMount() {
		if (this.props.user.typeCode !== 'guest') {
			request(arc.path.store, {
				service: 'cart',
				action: 'store.customer.Account.get',
				params: [{
					order: 'desc',
				}],
			}).then((res)=>{
				const accounts = res && res.success ? res.data : [];

				this.setState({
					accounts,
					newCreditCard: accounts.length ? false : true,
				});
			}).catch(exc);

			request(arc.path.store, {
				service: 'cart',
				action: 'store.Address.get',
				options: {
					limit: {
						count: 100,
					},
				},
			}).then((res)=>{
				this.setState({
					addresses: res && res.success ? res.data : [],
				});
			}).catch(exc);
		} else {
			this.setState({
				accounts: [],
				newCreditCard: true,
				addresses: this.props.productOrders.map(el => el.shippingAddress),
			});
		}
	};

    state = {
		errorMessage: null,
		isBusy: null,
		selectedPayment: null,
		savePayment: true,
		accounts: null,
		newCreditCard: false,
		hasSubmitted: false,
		addresses: null,
	};

    handleSubmit = (e)=>{
		e.preventDefault();

		this.setState({hasSubmitted: true});

		// Special empty form submission prevention for Safari
		if (((this.props.cart.total - this.props.cart.giftcardAmount) > 0) && !this.state.selectedPayment && !this.refs.creditCardForm) {
			this.setState({errorMessage: 'Payment information required'});
			return;
		}

		this.setState({errorMessage: null, isBusy: true});

		new Promise((resolve, reject)=>{
			// todo: would be better not to infer whether payment is needed or not, server should be explicit
			if ((this.props.cart.total - this.props.cart.giftcardAmount) > 0) {
				if(this.state.selectedPayment) {
					resolve({
						params: [[{
							value: {
								id: this.state.selectedPayment.id,
							},
						}]],
						creditCardData: null,
					})
				} else {
					(this.refs.creditCardForm as any).getData().then((creditCardData) => {
						if (creditCardData) {
							resolve({
								params: [[{
									value: creditCardData,
								}]],
								creditCardData
							})
						} else {
							reject(new Error('Need more payment information'));
						}
					})
				}
			} else {
				resolve({
					params: [],
					creditCardData: null,
				});
			}
		}).then(({params, creditCardData}) => {
			request(arc.path.store, {
				service: 'cart',
				action: 'store.Cart.completePurchase',
				params: params,
			}).then((res)=>{
				if (res && res.success) {
					setPurchase(res.data);

					document.body.dispatchEvent(
						new CustomEvent('arc_purchase', {
							detail: {
								purchase: res.data,
							}
						})
					);

					if (this.state.savePayment && this.props.user.typeCode !== 'guest' && creditCardData) {
						// Attempt to save payment account, but ignores failure.
						// Successful payment is the objective, silent failure of this call won't hurt anything.
						request(arc.path.store, {
							service: 'cart',
							action: 'store.customer.Account.savecc',
							options: {
								value: creditCardData,
							},
						});
					}

					history.replace(arc.path.cart+'complete?id='+res.data.code);
				} else {
					let errorMessage;

					switch (res.errorCode) {
						case 'E050DAJ':
							errorMessage = (<div>Your desired delivery date may have expired or is otherwise invalid. Please <a href={arc.path.cart+'checkout'}>select a new delivery date</a>.</div>)
							break;
						case 'E050DAI':
							errorMessage = (<div>Please <a href={arc.path.cart+'checkout'}>select a shipping method</a>.</div>)
							break;
						default:
							errorMessage = res.message || 'Unspecified Error.  Sorry, your order could not be submitted at this time';
							break;
					}

					this.setState({
						isBusy: null,
						errorMessage,
					})
				}
			}).catch(exc);
		}).catch(err => {
			this.setState({
				isBusy: null,
				errorMessage: err.toString(),
			})
		})
	};

    renderNoPayment = ()=>{
		return (
			<div>
				<p>No payment info needed for this order.</p>
			</div>
		);
	};

    renderPayment = ()=>{
    	const accountsWithAddresses = this.state?.accounts?.filter(x => x.address);

		if (!this.state.accounts) return null;

		return (
			<div>
				{
					!!accountsWithAddresses?.length && (
						<FormContainer>
							{
								accountsWithAddresses.map(el => (
								<FormSection key={el.id}>
									<FormRadio>
										<input required name='payment-method' type='radio' value={el.id} onChange={()=> this.setState({newCreditCard: false, selectedPayment: el})}/>
										<CreditCardBar card={el}/>
									</FormRadio>
								</FormSection>
								))
							}
							<FormSection>
								<FormRadio>
									<input required name='payment-method' type='radio' value='new' onChange={()=> this.setState({newCreditCard: true, selectedPayment: null})}/>
									<div>New Credit Card</div>
								</FormRadio>
							</FormSection>
						</FormContainer>
					)
				}
				{
					this.state.addresses && this.state.newCreditCard ? (
						<div>
							<CreditCardForm addresses={this.state.addresses} ref='creditCardForm'/>
							{
								this.props.user.typeCode !== 'guest' ? (
									<label className='SelectPayment_save-payment'>
										<input type='checkbox' checked={this.state.savePayment} onChange={()=> this.setState({savePayment: !this.state.savePayment})}/>
										<div>Save this information for faster checkout next time</div>
									</label>
								) : null
							}
						</div>
					) : null
				}
			</div>
		)
	};

    render() {
		const requirePayment = (this.props.cart.total - this.props.cart.giftcardAmount) > 0;

		return (
			<div className='SelectPayment'>
				<CartHeading heading='Payment' user={this.props.user} location={this.props.location}/>
				<hr/>
				<h2>Payment Method</h2>
				{
					requirePayment && arc.giftCard.enabled && <GiftCard giftcards={this.props.cart.giftcards}/>
				}
				<form onSubmit={this.handleSubmit} className={this.state.hasSubmitted ? 'validate' : ''}>
					{
						requirePayment ? this.renderPayment() :  this.renderNoPayment()
					}
					{
						this.state.errorMessage ? (
							<div className='SelectPayment_error'>
								<Alert type='error'>{this.state.errorMessage}</Alert>
							</div>
						) : null
					}
					<CartContinue isBusy={this.state.isBusy} title='Complete Purchase'/>
				</form>
			</div>
		);
	}
}

export interface Props {
	cart: any,
	user: any,
	location: any,
	productOrders: any[],
}

export default class extends React.Component<Props> {
    meetsRequirements = () => {
		const orders = this.props.productOrders;

		// todo: move this logic to backend
		// https://jira.skyrin.com/browse/DC-761
		if ((orders.filter(el => !el.shippingAddressId).length) || (
			arc.requireShippingMethod ? orders.filter(el => !el.shippingMethodId).length : false
		)) {
			return false;
		} else {
			return orders;
		}
	};

    componentDidMount() {
		if (!this.meetsRequirements()) {
			history.replace(arc.path.cart+'checkout');
		}
	}

    componentWillReceiveProps() {
		if (!this.meetsRequirements()) {
			history.replace(arc.path.cart+'checkout');
		}
	}

    render() {
		return this.meetsRequirements() ? <Component {...this.props}/> : null;
	}
}
