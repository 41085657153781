import * as React from "react";
import AddressForm from '../AddressForm';
import * as request from 'arcdynamic-request';
import { setCart } from '../../actions';
import exc from '../../exception';

export interface Props {
	orderIndex: number,
	shippingAddress: any,
	onSuccess: any,
}

export default class extends React.Component<Props> {
	state = {
		edit: false,
		isBusy: null,
	};

    submit = (address)=>{
		Promise.all([
			request(arc.path.store, {
				service: 'cart',
				action: 'store.cart.Order.update',
				params: [this.props.orderIndex],
				options: {
					value: {address},
				}
			}),
			request(arc.path.store, {
				service: 'cart',
				action: 'store.Cart.get',
			}),
		]).then(values => {
			// todo: display errors
			this.setState({isBusy: null});

			if (values[0].success) {
				setCart(values[1].data);
				this.props.onSuccess();
			}
		}).catch(exc);
	};

    render() {
		return (
			<div>
				<AddressForm autoFocus={false} address={this.props.shippingAddress} isShippingAddress={true} onSuccess={this.submit} submitButtonText='Ship to this address' saveAddress={false}/>
			</div>
		);
	}
}
