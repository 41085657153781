import * as React from "react";

export interface Props {
	title: string,
	disabled?: boolean,
	isBusy?: boolean,
	children: any,
}

export default ({title, disabled=false, isBusy=false, children=null})=> (
	<div className='CartContinue'>
		{
			children ? <div className='CartContinue_message'>{children}</div> : null
		}
		<button data-is-busy={isBusy ? true : null} disabled={disabled}>{title}</button>
	</div>
);