export default (shippingMethods, arrMethodIds)=>{
	if(!arrMethodIds) return null;

	let cheapest = null;

	arrMethodIds.forEach(shippingMethodId =>{
		shippingMethods.forEach(x=>{
			if(x.id === shippingMethodId){
				if (cheapest === null) {
					cheapest = x;
				} else if(x.amount < cheapest.amount) {
					cheapest = x;
				}
			}
		});
	});

	return cheapest;
};