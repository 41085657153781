import * as React from "react";

export interface Props {
	src?: string,
	desc?: string,
}

export default ({src, desc}: Props)=> (
	<div className='Branding'>
		{src ? <img className='Branding_logo' src={src} alt='our logo'/> : null}
		{desc ? <div className='Branding_body'>{desc}</div> : null}
	</div>
);