import * as React from "react";

export interface Props {
	characterLimit?: number,
	value: string,
	setShippingInstructions: any,
}

export default class extends React.Component<Props> {
    static defaultProps = {
        characterLimit: 400,
    };

    setValue = (e) => {
		this.props.setShippingInstructions(e.target.value.slice(0, this.props.characterLimit));
	};

    render() {
		return (
			<div className='OrderShippingInstruction'>
				<h2>Shipping Instructions</h2>
				<textarea
					value={this.props.value}
					onChange={this.setValue}
					maxLength={this.props.characterLimit}
					placeholder='Optional'
				/>
			</div>
		);
	}
}
