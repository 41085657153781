import * as React from "react";
import Login from '../Login';
import AccountRegister from '../AccountRegister';
import Request from '../Request';
import Reset from '../Reset';

export interface Props {
	title: string,
	allowGuest: boolean,
	onSuccess?: any,
}

export default class extends React.Component<Props> {
    state = {
		mode: 'login',
	};

    setMode = (mode)=>{
		this.setState({mode});
	};

    render() {
		let content = null;

		switch (this.state.mode) {
			case 'login':
				content = (
					<Login 
						title={this.props.title} 
						allowGuest={this.props.allowGuest} 
						onSuccess={this.props.onSuccess}
						resetPassword={()=> this.setMode('requestPassword')}
						register={()=>this.setMode('register')} />
				);
				break;
			case 'register':
				content = (
					<AccountRegister 
						mode='signup' 
						verify={false}
						register={this.props.onSuccess}
						activate={()=>this.setMode('newLogin')}
						login={()=>this.setMode('login')}/>
				);
				break;
			case 'verify':
				content = (
					<AccountRegister 
						mode='verify' 
						activate={()=>this.setMode('newLogin')}
						login={()=>this.setMode('login')}/>
				);
				break;
			case 'newLogin':
				content = (
					<div>
						<div>
							<p>Thank you for signing up.</p>
						</div>
						<Login 
							newAccount={true} 
							allowGuest={false} 
							onSuccess={this.props.onSuccess}
							resetPassword={()=> this.setMode('requestPassword')}
						/>
					</div>
				);
				break;
			case 'requestPassword':
				content = (
					<Request onRequest={()=> this.setMode('resetPassword')} />
				);
				break;
			case 'resetPassword':
				content = (
					<Reset onReset={()=> this.setMode('login')} />
				);
				break;
		}

		return (
			<div className='Authenticate'>
				{content}
			</div>
		);
	}
}
