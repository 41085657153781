import * as React from "react";
import { setCart } from '../../actions'
import store from '../../store';
import * as request from 'arcdynamic-request';
import exc from '../../exception';

export interface Props {
	orderIndex: number,
	autoRenewStatusCode: string,
}

export default class extends React.Component<Props> {
    componentWillUnmount() {
		const autoRenewStatusCode = (this.refs.checkbox as HTMLInputElement).checked ? 'active' : 'inactive';

		if (this.props.autoRenewStatusCode !== autoRenewStatusCode) {
			request(arc.path.store, {
				service: 'cart',
				action: 'store.cart.Subscription.setAutoRenewStatus',
				params: [
					this.props.orderIndex,
					autoRenewStatusCode
				]
			}).then((res)=>{
				if (res && res.success) {
					store.setState(setCart(res.data))
				}
			}).catch(exc);
		}
	}

    render() {
		return (
			<div className='OrderSubscription'>
				<label>
					<input type='checkbox' defaultChecked={this.props.autoRenewStatusCode === 'active'} ref='checkbox'/> Auto-renew subscription
				</label>
			</div>
		);
	}
}
