import * as React from "react";
import months from '../../months';

var getCalendar = function(date) {
	const d = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth()));
	const month = d.getUTCMonth();
	const days = [];

	// Pad calendar with days from the previous month, if the first of the month does not coincide with the first of the week.
	d.setUTCDate(d.getUTCDate() - d.getUTCDay());

	// Days from previous month
	while (d.getUTCMonth() !== month) {
		days.push({
			date: new Date(d.getTime()),
			month: month,
		});
		d.setUTCDate(d.getUTCDate()+1);
	}

	// Days from current month
	while (d.getUTCMonth() === month) {
		days.push({
			date: new Date(d.getTime()),
			month: month,
		});
		d.setUTCDate(d.getUTCDate()+1);
	}

	// Days from upcoming month
	if (days.length % 7 !== 0) {
		for (let i = days.length % 7; i < 7; i++) {
			days.push({
				date: new Date(d.getTime()),
				month: month,
			});
			d.setUTCDate(d.getUTCDate()+1);
		}
	}

	const weeks = [];

	while (days.length) {
		weeks.push(days.splice(0, 7));
	}

	return weeks;
}

export interface Props {
	date: Date,
	minDate: Date,
	maxDate: Date,
	format: any,
}

export default class extends React.Component<Props> {
    state = {
        date: this.props.date && !isNaN(this.props.date.getTime()) ? this.props.date : new Date(),
    };

    private prevMonth = ()=> {
    	const { date } = this.state;
    	this.setState({date: new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth()-1))});
    };

    private nextMonth = ()=> {
    	const { date } = this.state;
    	this.setState({date: new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth()+1))});
    };

    render() {
    	let date = this.state.date;
		let {minDate, maxDate, format} = this.props;
		
		minDate = minDate && !isNaN(minDate.getTime()) ? minDate : null;
		maxDate = maxDate && !isNaN(maxDate.getTime()) ? maxDate : null;

		if (minDate && minDate > date) { // Floor
			date = minDate;
		}

		if (maxDate && maxDate < date) { // Ceil
			date = maxDate;
		}

		const firstDay = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth()));
		const lastDay = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth() + 1, 0));

		return (
			<div className='Calendar'>
				<div className='Calendar_head'>
					<button type='button' onClick={this.prevMonth} disabled={minDate && minDate >= firstDay} className='Calendar_head_btn Calendar_head_btn--prev' aria-label='previous month'/>
					<div className='Calendar_head_month'>
						{months[date.getUTCMonth()]} {date.getUTCFullYear()}
					</div>
					<button type='button' onClick={this.nextMonth} disabled={maxDate && maxDate <= lastDay} className='Calendar_head_btn Calendar_head_btn--next' aria-label='next month'/>
				</div>
				<div className='Calendar_week'>
					<abbr title='Sunday'>Sun</abbr>
					<abbr title='Monday'>Mon</abbr>
					<abbr title='Tuesday'>Tue</abbr>
					<abbr title='Wednesday'>Wed</abbr>
					<abbr title='Thursday'>Thu</abbr>
					<abbr title='Friday'>Fri</abbr>
					<abbr title='Saturday'>Sat</abbr>
				</div>
				<div className='Calendar_body'>
					{
						getCalendar(date).map((week,i) => (
							<div key={i} className='Calendar_row'>
								{
									week.map((data) => format(data.date, data.month))
								}
							</div>
						))
					}
				</div>
			</div>
		);
	}
}
