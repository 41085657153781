import * as React from "react";

export interface Props extends React.HTMLProps<HTMLSelectElement> {
	text: string,
	placeholder?: string,
}

export default class extends React.Component<Props> {
    state = {
        focus: false,
    };

    render() {
		const {value = '', placeholder = '', text, onFocus, onBlur, ...rest} = this.props;

		return (
			<label className={'Select'+(value ? ' Select--fill' : '')+(this.state.focus ? ' Select--focus' : '')}>
				<div className='Select_label'>{placeholder}</div>
				<div className={'Select_value'+(!text ? ' Select_value--placeholder' : '')}>{text || placeholder}</div>
				<span className='Select_arrow'/>
				<select onFocus={(e)=> {
					this.setState({focus: true})
					if (typeof onFocus === 'function') {
						onFocus(e);
					}
				}} onBlur={(e)=> {
					this.setState({focus: false})
					if (typeof onBlur === 'function') {
						onBlur(e);
					}
				}} value={value} {...rest}/>
			</label>
		);
	}
}