import * as React from "react";
import * as request from 'arcdynamic-request';
import cart from '../../cart';
import Alert from '../Alert';
import currency from '../../currency';
import exc from '../../exception';

export interface Props {
	giftcards: any[],
}

export default class extends React.Component<Props> {
    state = {
		isBusy: null,
		alert: null,
		useGiftCard: false,
	};

	private pin;
	private code;

	private handleSubmit = (e)=>{
		e.preventDefault();

		this.setState({
			isBusy: true,
			alert: null,
		});

		Promise.all([
			request(arc.path.store, {
				service: 'cart',
				action: 'store.cart.Giftcard.update',
				params: [this.code.value, this.pin.value],
			}),
			cart.getCart(),
		]).then(values =>{
			const res = values[0];

			if (res && res.success) {
				this.setState({
					isBusy: null,
				});
			} else {
				this.setState({
					isBusy: null,
					alert: {
						type: 'error',
						message: res && res.message ? res.message : 'Sorry, we were unable to add gift card at this time.',
					}
				});
			}
		}).catch(exc);
	};

	private remove = (e, giftcard)=>{
		e.preventDefault();

		this.setState({
			isBusy: giftcard.id,
		});

		Promise.all([
			request(arc.path.store, {
				service: 'cart',
				action: 'store.cart.Giftcard.purge',
				params: [giftcard.id],
			}),
			cart.getCart(),
		]).then(values =>{
			const res = values[0];

			if (res && res.success) {
				this.setState({
					isBusy: null,
				});
			} else {
				this.setState({
					isBusy: null,
					alert: {
						type: 'error',
						message: res && res.message ? res.message : 'Sorry, we were unable to remove the gift card at this time.',
					}
				});
			}
		}).catch(exc)
	};

    render() {
		return (
			<div className='GiftCard'>
				<label className='GiftCard_toggle'>
					<input type='checkbox' checked={this.state.useGiftCard} onChange={()=> this.setState({useGiftCard: !this.state.useGiftCard})}/> I have a gift card
				</label>
				{
					this.state.useGiftCard ? (
						<div className='GiftCard_body'>
							<form onSubmit={this.handleSubmit}>
								<input required ref={x => this.code = x} type='text' placeholder='Enter Code' autoFocus autoComplete='off'/> 
								<input required ref={x => this.pin = x} type='text' placeholder='PIN' autoComplete='off'/> 
								<button data-is-busy={this.state.isBusy === true ? true : null}>Apply</button>
							</form>
							{
								this.state.alert ? (
									<div className='GiftCard_alert'>
										<Alert type={this.state.alert.type}>{this.state.alert.message}</Alert>
									</div>
								) : null
							}
							{
								this.props.giftcards.length ? (
									<div className='GiftCard_list'>
										<div>
											<Alert type='success'>The following giftcards will be applied to this purchase.</Alert>
										</div>
										{
											this.props.giftcards.map(x=>(
												<div key={x.id} className='GiftCard_list_item'>
													<div className='GiftCard_list_item_message'>
														{currency(x.amount)} balance for code ending in {x.code}
													</div>
													<button data-is-busy={this.state.isBusy === x.id ? true : null} onClick={(e)=>this.remove(e,x)}>Remove</button>
												</div>
											))
										}
									</div>
								) : null
							}
						</div>
					) : null
				}
			</div>
		);
	}
}
