import * as React from "react";
import CartSingleColumn from '../CartSingleColumn';
import AddressList from '../AddressList';
import CardList from '../CardList';
import UserInfo from '../UserInfo';
import auth from '../../auth';
import exc from '../../exception';
import Alert from '../Alert';
import currency from '../../currency';

export interface Props {
	user?: any,
}

export default class extends React.Component<Props> {
    logout = () => {
		auth.logout().then(res => {
			if (res && res.success) {
				window.location.href = arc.path.base;
			}
		}).catch(exc);
	};

    render() {
		return (
			<CartSingleColumn>
				<div className='Account'>
					<h1>Your Account</h1>
					{
						this.props.user.accountBalance ? (
							<div>
								<p><Alert type='success'>Account Balance: <span>{currency(this.props.user.accountBalance)}</span></Alert></p>
							</div>
						) : null
					}
					<h2>Personal Info</h2>
					<UserInfo user={this.props.user} />
					<hr/>
					<h2>Order History</h2>
					<p><a href={arc.path.cart+'account/order-history'}>View your orders</a></p>
					<hr/>
					<h2>Address Book</h2>
					<AddressList/>
					<hr/>
					<h2>Payment Methods</h2>
					<CardList/>
					<hr/>
					<button className='submit-button' onClick={this.logout}>Sign Out</button>
				</div>
			</CartSingleColumn>
		);
	}
}
