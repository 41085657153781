import * as React from "react";
import currency from '../../currency';

export interface Props {
	order: any,
}

export default class extends React.Component<Props> {
    render() {
		const order = this.props.order;
		let productCount = 0,
			subscribedProductCount = 0;

		order.products.forEach(x=>{
			if(x.subscriptionPeriod || x.productSubscriptionPeriod){
				subscribedProductCount += x.quantity;
			} else {
				productCount += x.quantity;
			}
		});

		return (
			<div className='Summary'>
				<div className='Summary_line'>
					<span className='Summary_label'>Items {productCount ? <span>({productCount})</span> : null}</span> {currency(order.subTotal)}
				</div>
				{
					order.subscriptionAmount && parseFloat(order.subscriptionAmount) ? (
						<div className='Summary_line'>
							<span className='Summary_label'>Subscribed Items {subscribedProductCount ? <span>({subscribedProductCount})</span> : null}</span> {currency(order.subscriptionAmount)}
						</div>
					) : null
				}
				{
					(order.totalDiscountCurrencyAmount + order.totalDiscountPercentAmount) ? (
						<div className='Summary_line'>
							<span className='Summary_label'>Discounts</span> -{currency(order.totalDiscountCurrencyAmount + order.totalDiscountPercentAmount)}
						</div>
					) : null
				}
				<div className='Summary_line'>
					<span className='Summary_label'>Shipping</span> {currency(order.shippingAmount - order.totalDiscountShippingAmount)}
				</div>
				{
					order.taxAmount && parseFloat(order.taxAmount) ? (
						<div className='Summary_line'>
							<span className='Summary_label'>Tax</span> {currency(order.taxAmount)}
						</div>
					) : null
				}
				{
					order.giftcardAmount ? (
						<div className='Summary_line'>
							<span className='Summary_label'>Giftcards</span> -{currency(order.giftcardAmount)}
						</div>
					) : null
				}
				<hr/>
				<div className='Summary_line Summary_line--total'>
					<span className='Summary_label'>Subtotal</span> {currency(order.total - (order.giftcardAmount?order.giftcardAmount:0))}
				</div>
			</div>
		);
	}
}
