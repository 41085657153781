import * as React from "react";
import * as svgCCMastercard from '../../svgs/cc-mastercard.svg';
import * as svgCCAmex from '../../svgs/cc-amex.svg';
import * as svgCCVisa from '../../svgs/cc-visa.svg';
import * as svgCCDinersClub from '../../svgs/cc-diners-club.svg';
import * as svgCCJcb from '../../svgs/cc-jcb.svg';
import * as svgCCDiscover from '../../svgs/cc-discover.svg';
import * as svgCreditCard from '../../svgs/credit-card.svg';
import Icon from '../Icon';

const icons = {
	'visa': svgCCVisa,
	'mastercard': svgCCMastercard,
	'americanexpress': svgCCAmex,
	'dinersclub': svgCCDinersClub,
	'discover': svgCCDiscover,
	'jcb': svgCCJcb,
};

export default ({cardType}: {cardType?: string})=> (
	<Icon svg={icons[cardType] || svgCreditCard}/>
);