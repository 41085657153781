import * as React from "react";
import CartSingleColumn from '../CartSingleColumn';
import * as request from 'arcdynamic-request';
import OrderDetail from '../OrderDetail';
import PaginateButtons from '../PaginateButtons';
import Results from '../Results';
import exc from '../../exception';

const limitCount = 5;

export default class extends React.Component {
	state = {
		items: null,
		totalCount: null,
		page: 1,
	};

    private fetch = (page)=>{
		request(arc.path.store, {
			service: 'cart',
			action: 'store.Order.get',
			params: [{
				filter: {},
				limit: {
					count: limitCount,
					offset: (limitCount * page) - limitCount,
				},
				flag: {
					product: true,
					shipment: true,
					address: true,
					user: true,
					purchase: true,
					discount: true,
					subscription: true,
					purchase_billingAddress: true,
				},
				order: 'desc',
				count: 2,
			}]
		}).then((res)=>{
			if (res && res.success) {
				this.setState({
					items: res.data,
					totalCount: res.count,
					page: page,
				})
			} else {
				this.setState({
					items: [],
					totalCount: false,
					page: page,
				})
			}
		}).catch(exc);
	};

	private nextPage = ()=> {
		window.scrollTo(0, 0);
		this.fetch(this.state.page+1);
	}

	private prevPage = ()=> {
		window.scrollTo(0, 0);
		this.fetch(this.state.page-1);
	}

    componentDidMount() {
		this.fetch(this.state.page);
	}

    render() {
		if (this.state.items == null) return null;

		return (
			<CartSingleColumn>
				<div className='OrderHistory'>
					<h1>Order History</h1>
					<div className='OrderHistory_nav'>
						<Results count={limitCount} page={this.state.page} itemCount={this.state.items.length} totalCount={this.state.totalCount}/>
					</div>
					{
						this.state.items.map(el => <OrderDetail key={el.id} order={el}/>)
					}
					<PaginateButtons
						count={limitCount}
						page={this.state.page}
						itemCount={this.state.items.length}
						totalCount={this.state.totalCount}
						onNext={this.nextPage}
						onPrev={this.prevPage}
					/>
				</div>
			</CartSingleColumn>
		);
	}
}
