import * as React from "react";
import CreditCardIcon from '../CreditCardIcon';

const names = {
	'visa': 'Visa',
	'mastercard': 'MasterCard',
	'americanexpress': 'American Express',
	'dinersclub': 'Diners Club',
	'discover': 'Discover',
	'jcb': 'JCB',
};

export default ({card}: {card: any})=> (
	<div className='CreditCardBar'>
		<span className='CreditCardBar_logo'><CreditCardIcon cardType={card.cardType}/></span>
		<div className='CreditCardBar_body'>
			<div className='CreditCardBar_cc'>
				<span className='CreditCardBar_cc_name'>{names[card.cardType] || 'Credit Card'} ****{card.lastFour}</span>
				<span className='CreditCardBar_cc_exp'>Exp: {('0'+card.expirationMonth).slice(-2)}/{card.expirationYear}</span>
			</div>
			{
				card.address ? (
					<div>
						<div>{card.address.addressee}</div>
						<div><span className='CreditCardBar_nowrap'>{card.address.addressOne},</span> <span className='CreditCardBar_nowrap'>{card.address.city}, {card.address.state} {card.address.postalCode}</span></div>
					</div>
				) : null
			}
		</div>
	</div>
);