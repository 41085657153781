import * as React from "react";

export interface Props {
	children: any,
	location: any,
}

export default ({children, location}: {children: any, location: any})=>(
	<div className='CartTabs'>
		<div className='CartTabs_main'>
			<a href={arc.path.cart} className={'CartTabs_crumb'+(location.pathname === arc.path.cart ? ' is-active' : '')}>Cart</a>
			<span className='CartTabs_divider' role='separator'>›</span>
			<a href={arc.path.cart+'checkout'} className={'CartTabs_crumb'+(location.pathname === arc.path.cart+'checkout' ? ' is-active' : '')}>Shipping</a>
			<span className='CartTabs_divider' role='separator'>›</span>
			<a href={arc.path.cart+'payment'} className={'CartTabs_crumb'+(location.pathname === arc.path.cart+'payment' ? ' is-active' : '')}>Payment</a>
		</div>
		<div className='CartTabs_email'>{children}</div>
	</div>
);
