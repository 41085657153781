import * as React from "react";
import { useEffect } from 'react';
import CartHeader from '../CartHeader';

export default function Root({ cart, user, location, children }) {
	useEffect(()=> {
		document.body.dispatchEvent(
			new CustomEvent('arc_page_view', {
				detail: {
					cart,
					user,
					location,
				}
			})
		);
	}, [location.pathname+location.search]);

	return (
		<div className='Root'>
			<header>
				<CartHeader productCount={cart?.productCount} user={user}/>
			</header>
			<main className='Root_main'>
				{children}
			</main>
		</div>
	)
}
