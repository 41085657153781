import * as React from "react";

export interface Props {
	count: number,
	page: number,
	totalCount: number|false,
	itemCount: number,
	onPrev: any,
	onNext: any,
}

export default class extends React.Component<Props> {
    static defaultProps = {
        page: 1, 
    };

    render() {
		let hasMore = null;

		// Display nothing if there is less than 1 page of items.
		if ((this.props.totalCount !== false && this.props.itemCount === this.props.totalCount) || (this.props.page === 1 && this.props.itemCount < this.props.count)) {
			return null;
		}

		if (this.props.totalCount !== false) {
			// If totalCount is false then it was probably too inefficient to get the count for this query.
			hasMore = this.props.count * this.props.page < this.props.totalCount;
		} else {
			// There are probably more results if the itemCount matches the request count.
			hasMore = this.props.itemCount > 0 && this.props.itemCount == this.props.count;
		}

		return (
			<div className='PaginateButtons'>
				<button className='PaginateButtons_btn PaginateButtons_btn--prev' onClick={this.props.onPrev} disabled={this.props.page <= 1}>
					« Prev
				</button>
				<button className='PaginateButtons_btn' onClick={this.props.onNext} disabled={!hasMore}>
					Next »
				</button>
			</div>
		);
	}
}