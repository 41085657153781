import * as React from "react";
import * as request from 'arcdynamic-request';
import CreditCardForm from '../CreditCardForm';
import Alert from '../Alert';
import exc from '../../exception';

export interface Props {
	onSuccess?: any,
	card?: any,
	onCancel?: any,
}

export default class extends React.Component<Props> {
	state = {
		errorMessage: null,
		isBusy: null,
		hasSubmitted: false,
		addresses: null,
	};

    componentDidMount() {
		request(arc.path.store, {
			service: 'cart',
			action: 'store.Address.get',
			options: {
				limit: {
					count: 100,
				},
			},
		}).then((res)=>{
			this.setState({
				addresses: res && res.success ? res.data : [],
			});
		}).catch(exc);
	}

    handleSubmit = (e)=>{
		e.preventDefault();

		this.setState({
			isBusy: true,
			errorMessage: null,
		});

		// Triggers :invalid styles for Safari, since it lack HTML5 validation
		this.setState({hasSubmitted: true});

		(this.refs.creditCardForm as any).getData().then(cc => {
			if (cc) {
				request(arc.path.store, {
					service: 'cart',
					action: 'store.customer.Account.savecc',
					options: {
						value: cc,
					},
				}).then(res => {
					if(res && res.success){
						this.props.onSuccess(res.data);

						this.setState({
							edit: false,
							editCard: null,
							isBusy: null
						});
					} else {
						this.setState({
							isBusy: null,
							errorMessage: res && res.message ? res.message : 'Unable to save payment information',
						})
					}
				}).catch(exc)
			} else {
				this.setState({
					isBusy: null,
					errorMessage: null,
				});
			}
		}).catch(exc);
	};

    render() {
		if (!this.state.addresses) return null;

		return (
			<form className={'SaveCreditCard'+(this.state.hasSubmitted ? ' validate' : '')} onSubmit={this.handleSubmit}>
				<CreditCardForm addresses={this.state.addresses} ref='creditCardForm' card={this.props.card}/>
				{
					this.state.errorMessage ? (
						<div className='SaveCreditCard_error'>
							<Alert type='error'>{this.state.errorMessage}</Alert>
						</div>
					) : null
				}
				<div className='SaveCreditCard_edit'>
					{
						this.props.onCancel ? (
							<button type='button' onClick={this.props.onCancel}>Cancel</button>
						) : null
					}
					<button className='submit-button' data-is-busy={this.state.isBusy}>Save</button>
				</div>
			</form>
		);
	}
}
