import * as React from "react";
import Alert from '../Alert';
import * as request from 'arcdynamic-request';
import exc from '../../exception';

export interface Props {
	signUp: any,
}

export default class extends React.Component<Props> {
	state = {
		isBusy: null,
		errorMessage: null,
	};

	private username;
	private password;
	private passwordConfirm;
	
    private onError = (errorMessage)=>{
		this.setState({errorMessage});
	};

	private handleSubmit = (e)=>{
		e.preventDefault();

		if (this.state.isBusy) return;

		const username = this.username.value;
		const password = this.password.value;
		const passwordConfirm = this.passwordConfirm.value;

		if(!username) return this.onError('Please enter an e-mail');
		if(!password) return this.onError('Please enter a password');
		if(!passwordConfirm) return this.onError('Please retype the password');
		if(password !== passwordConfirm) return this.onError('Passwords do not match');

		this.setState({isBusy: true});

		request(arc.path.store, {
			service: 'cart',
			action: 'store.Account.register',
			params: [username],
			options: {
				value: {
					password
				}
			}
		}).then((res)=>{
			if (res && res.success) {
				this.props.signUp();
			}

			this.setState({
				isBusy: null,
				errorMessage: res && !res.success ? res.message : null,
			});
		}).catch(exc);
	};

    render() {
		return (
			<div className='SignUp'>
				<h1>Create account</h1>
				<form onSubmit={this.handleSubmit}>
					<div className='SignUp_field'>
						<input type='email' ref={x => this.username = x} name='username' required autoFocus placeholder='Email' autoComplete='username'/>
					</div>
					<div className='SignUp_field'>
						<input type='password' ref={x => this.password = x} name='password' required placeholder='Password' autoComplete='new-password' pattern='.{8,}' title='Must be at least 8 characters'/>
					</div>
					<div className='SignUp_field'>
						<input type='password' ref={x => this.passwordConfirm = x} name='passwordConfirm' required placeholder='Confirm Password' autoComplete='new-password' pattern='.{8,}' title='Must be at least 8 characters'/>
					</div>
					<p>Password must be at least 8 characters</p>
					<button type='submit' data-is-busy={this.state.isBusy}>Submit</button>
				</form>
				{
					this.state.errorMessage ? (
						<div className='SignUp_error'>
							<Alert type='error'>{this.state.errorMessage}</Alert>
						</div>
					) : null
				}
			</div>
		);
	}
}
