import * as React from "react";
import history from '../../history';
import Request from '../Request';
import Reset from '../Reset';

export interface Props {
	verificationKey?: string,
}

export default class extends React.Component<Props> {
	state = {
		mode: this.props.verificationKey ? 'verify' : null,
	};

    reset = ()=>{
		history.push(arc.path.cart+'account');
	};

    setMode = (mode)=>{
		this.setState({mode});
	};

    render() {
		return (
			<div className='ResetPassword'>
				<div className='ResetPassword_body'>
					{
						this.state.mode === 'verify' ? (
							<Reset onReset={this.reset} verificationKey={this.props.verificationKey || null} />
						) : (
							<Request onRequest={()=> this.setMode('verify')} />
						)
					}
				</div>
			</div>
		);
	}
}
