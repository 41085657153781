import * as React from "react";
import currency from '../../currency';
import * as resize from 'arcdynamic-resize';

const thumbSize = 94;

export interface Props {
	orderProduct: any,
	discountAmount: number,
}

export default class extends React.Component<Props> {
    render() {
		const product = this.props.orderProduct;
		const discountAmount = this.props.discountAmount;
		const href = arc.path.product+product.sku;
		const thumbUrl = product.files.find(el => el.type === 'image')?.url;
		const src = thumbUrl ? resize(thumbUrl, thumbSize, thumbSize) : null;

		return (
			<div className='CartProduct'>
				<div className='CartProduct_img'>
					{
						src && (
							<a href={href} className='CartProduct_img_link'>
								<img src={src} alt={product.name}/>
							</a>
						)
					}
				</div>
				<div className='CartProduct_body'>
					<a href={href}>{product.name}</a>
					{
						product.fixedAttributeValues ? product.fixedAttributeValues.map(el => <div key={el.attributeId}>{el.name}</div>) : null
					}
					{
						product.subscriptionPeriod ? (
							<div>
								Subscription: {product.subscriptionPeriod.name}
								{
									product.subscriptionPeriod.price ? <div>{currency(product.subscriptionPeriod.price)}</div> : null
								}
							</div>
						) : product.price ? <div className={discountAmount ? 'CartProduct_body_price--discount' : null}>{currency(product.price)}</div> : null
					}
					{
						discountAmount ? <div>{currency(product.price - discountAmount)}</div> : null
					}
				</div>
			</div>
		);
	}
}
