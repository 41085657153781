import * as React from "react";
import SignUp from '../SignUp';
import Verify from '../Verify';
import cart from '../../cart';
import auth from '../../auth';
import exc from '../../exception';

export interface Props {
	mode?: string,
	verificationKey?: string,
	verify?: boolean,
	register?: any,
	activate?: any,
	login?:any,
}

export default class extends React.Component<Props> {
    static defaultProps = {
        mode: 'signup',
        verificationKey: null,
        verify: false,
    };

    state = {
        mode: this.props.mode,
    };

    signUp = () => {
		if(this.props.verify) {
			this.setState({
				mode: 'verify',
			});
		} else {
			Promise.all([
				cart.getCart(),
				auth.getUser(),
			]).then(() => {
				if(this.props.register) {
					this.props.register();
				}				
			}).catch(exc);
		}
	};

    render() {
		let content = null;

		switch (this.state.mode) {
			case 'signup':
				content = <SignUp signUp={this.signUp} />
				break;
			case 'verify':
				content = <Verify verificationKey={this.props.verificationKey} activate={this.props.activate} login={this.props.login} />;
				break;
		}
		return (
			<div className='AccountRegister'>
				<div className='AccountRegister_body'>
					{content}
				</div>
			</div>
		);
	}
}
