import * as React from "react";
import Alert from '../Alert';
import * as request from 'arcdynamic-request';
import exc from '../../exception';

export interface Props {
	verificationKey?: string,
	activate: any,
	login:any,
}

export default class extends React.Component<Props> {
	state = {
		errorMessage: null,
		isBusy: null,
	}

	private verificationKey: HTMLInputElement;

    onError = (message)=>{
		this.setState({errorMessage: message});
	};

    componentDidMount(){
		let verificationKey = this.props && this.props.verificationKey ? this.props.verificationKey : null;
		if(verificationKey) {
			this.verify(verificationKey);
		}
	}

    activate = (accountCode)=>{
		request(arc.path.store, {
			service: 'cart',
			action: 'store.Account.activate',
			params: [accountCode,null],
		}).then((res)=>{
			if (res && res.success) {
				this.props.activate();
			}

			this.setState({isBusy: null});

			if (!res.success) {
				this.setState({errorMessage: res.message});
			}
		}).catch(exc);
	};

    verify = (verificationKey)=>{
		if(!verificationKey) return this.onError('Please enter a verification key');

		this.setState({isBusy: true});

		request(arc.path.store, {
			service: 'cart',
			action: 'store.Account.verify',
			params: [verificationKey],
		}).then((res)=>{
			if (res && res.success) {
				this.activate(res.data);
			} else {
				this.setState({
					errorMessage: res.message,
					isBusy: null,
				});
			}
		}).catch(exc);
	};

    handleSubmit = (e)=>{
		e.preventDefault();

		const verificationKey = this.verificationKey.value;

		this.verify(verificationKey);
	};

    render() {
		return (
			<div className='AccountRegister_Verify'>
				<h1>Verify Account</h1>
				<p>
					Thank you for registering. Please enter the verification code sent to your registered e-mail.
				</p>
				<form onSubmit={this.handleSubmit}>
					<div className='AccountRegister_Verify_field'>
						<input type='text' ref={x => this.verificationKey = x} name='verificationKey' autoFocus placeholder='Verification key' defaultValue={this.props.verificationKey || null}/>
					</div>
					{
						this.state.errorMessage ? (
							<div>
								<Alert type='error'>{this.state.errorMessage}</Alert>
							</div>
						) : null
					}
					<button data-is-busy={this.state.isBusy} className='AccountRegister_Verify_btn AccountRegister_Verify_btn--verify'>Submit</button>
				</form>
				<button onClick={()=> this.props.login()} className='AccountRegister_Verify_btn AccountRegister_Verify_btn--verified'>I already verified my account</button>
			</div>
		);
	}
}
