import store from './store';
import * as querystring from 'querystring';

export function updateLocation(url) {
	const a = document.createElement('a');
	a.href = url;
	const { hash, host, search, href, pathname } = a;
	const query = querystring.parse(a.search.slice(1));

	store.setState({
		location: { 
			hash, 
			host, 
			pathname,
			search, 
			query, 
			href,
		}
	});
}

export function setPurchase(data) {
	store.setState({
		purchase: data
	});
}

export function setCart(cart) {
	let productOrders;

	if (cart && cart.orders) {
		productOrders = Object.keys(cart.orders).map(key => cart.orders[key]).filter(el => el.products.length);
	}

	let isCheckoutComplete = false;

	if (productOrders && productOrders.length) {
		// todo: move this logic to backend
		// https://jira.skyrin.com/browse/DC-761
		if (productOrders.filter(el => {
			return el.shippingAddressId && (arc.requireShippingMethod ? el.shippingMethodId : true)
		}).length === productOrders.length) {
			isCheckoutComplete = true;
		}
	}

	store.setState({
		cart,
		productOrders,
		isCheckoutComplete,
	});
}

export function setUser(data) {
	store.setState({
		user: data
	});
}