import * as React from "react";
import Order from '../Order';
import CartHeading from '../CartHeading';
import CartContinue from '../CartContinue';
import history from '../../history';

function submit(e) {
	e.preventDefault();
	history.push(arc.path.cart+'checkout');
}

export interface Props {
	cart: any,
	user: any,
	location: any,
	productOrders?: any[],
}

export default ({cart, productOrders, user, location}: Props)=> {
	if (cart === undefined) {
		// is fetching
		return null
	}
	
	return (
		<div className='Cart'>
			<CartHeading heading='Cart' user={user} location={location}/>
			{
				productOrders && productOrders.length ? (
					<div>
						{productOrders.map(el => <Order name={productOrders.length > 1 ? (el.name ? 'Cart for '+el.name : arc.cartName) : null} key={el.orderIndex} order={el}/>)}
						<form onSubmit={submit}>
							<CartContinue title='Checkout'/>
						</form>
					</div>
				) : (
					<p>Your cart is currently empty.</p>
				)
			}
		</div>
	);
}