import * as React from "react";
import * as request from 'arcdynamic-request';
import cart from '../../cart';
import exc from '../../exception';

export default class extends React.Component {
	state = {
		errors: [],
		coupons: [],
		isBusy: null,
	};

	private coupon;

    componentDidMount() {
		request(arc.path.store, {
			service: 'cart',
			action: 'store.cart.Discount.getCoupons',
		}).then((res)=>{
			if (res && res.success) {
				this.setState({coupons: res.data});
			}
		}).catch(exc)
	}

    removeCoupon = (code)=>{
		request(arc.path.store, {
			service: 'cart',
			action: 'store.cart.Discount.removeCoupon',
			params: [code],
		}).then((res)=>{
			if (res && res.success) {
				this.setState({coupons: res.data});
				cart.getCart();
			}
		}).catch(exc)
	};

    setCoupon = (tryCleanOnFail, value)=>{
		this.setState({isBusy: true});

		request(arc.path.store, {
			service: 'cart',
			action: 'store.cart.Discount.setCoupon',
			params: [value],
		}).then((res)=>{
			this.setState({isBusy: null});

			if (res && res.success) {
				this.setState({coupons: res.data});

				if (res.errors.length) {
					this.setState({errors: res.errors.map(el => el.message)});
				} else {
					this.coupon.value = '';
					cart.getCart();
				}
			} else if (res && res.message) {
				this.setState({errors: [res.message]});
			} else {
				this.setState({errors: ['Sorry, we were unable to apply your coupon code']});
			}
		}).catch(exc)
	};

    handleSubmit = (e)=>{
		e.preventDefault();
		this.setState({errors: []});
		this.setCoupon(true, this.coupon.value);
	};

    render() {
		return (
			<form className='ApplyDiscount' onSubmit={this.handleSubmit}>
				<div className='ApplyDiscount_body'>
					<input ref={x => this.coupon = x} type='text' aria-label='Discount Code' placeholder='Discount Code' autoComplete='off' required/> <button data-is-busy={this.state.isBusy}>Apply</button>
				</div>
				{
					this.state.coupons.length ? (
						<div className='ApplyDiscount_coupons'>
							{
								this.state.coupons.map(el => <button type='button' key={el} className='ApplyDiscount_coupon' onClick={()=> this.removeCoupon(el)}>{el} <span className='ApplyDiscount_icon'>×</span></button>)
							}
						</div>
					) : null
				}
				{
					this.state.errors.length ? (
						<div className='ApplyDiscount_message'>
							{
								this.state.errors.map((el,i) => <div key={i}>{el}</div>)
							}
						</div>
					) : null
				}
			</form>
		);
	}
}
