import * as React from "react";
import currency from '../../currency';

export interface Props {
	order: any,
	showShipping: boolean,
}

export default class extends React.Component<Props> {
    state = {
        show: false,
    };

    renderShipping = (shippingAmount) => {
		return (shippingAmount && this.state.show && this.props.showShipping) ? (
			<div className='CartSummaryItem_li'>
				<div className='CartSummaryItem_li_body'>Shipping</div>
				<span className='CartSummaryItem_li_total'>{currency(shippingAmount)}</span>
			</div>
		) : null
	};

    renderProducts = (items) => {
		if (!items.length) return null;

		return this.state.show ? (
			items.map(el => (
				<div key={el.productId} className='CartSummaryItem_li'>
					<div className='CartSummaryItem_li_body'>{el.name} x {el.quantity}</div>
					<span className='CartSummaryItem_li_total'>{currency(el.quantity * el.price)}</span>
				</div>
			))
		) : (
			<div className='CartSummaryItem_compact'>{items.map(el => el.quantity).reduce((a,b)=>a+b)} items in order</div>
		);
	};

    renderSubscriptions = (items) => {
		if (!items.length) return null;

		return this.state.show ? (
			items.map(el => (
				<div key={el.productId} className='CartSummaryItem_li'>
					<div className='CartSummaryItem_li_body'>{el.subscriptionPeriod.name} x {el.quantity}</div>
					<span className='CartSummaryItem_li_total'>{currency(el.quantity * el.subscriptionPeriod.price)}</span>
				</div>
			))
		) : (
			<div className='CartSummaryItem_compact'>{items.map(el => el.quantity).reduce((a,b)=>a+b)} subscriptions in order</div>
		);
	};

    render() {
		const order = this.props.order;
		let products = [];
		let subscriptions = [];

		order.products.forEach(el => {
			if(el.subscriptionPeriod || el.productSubscriptionPeriod){
				subscriptions.push(el)
			} else {
				products.push(el);
			}
		});

		const toggle = this.state.show ? (
			<button type='button' onClick={()=> this.setState({show: false})}>hide</button>
		) : (
			<button type='button' onClick={()=> this.setState({show: true})}>show</button>
		)

		return (
			<div className='CartSummaryItem'>
				<div><strong>{order.name ? 'Cart for '+order.name : arc.cartName}</strong> ({toggle})</div>
				{this.renderProducts(products)}
				{this.renderSubscriptions(subscriptions)}
				{this.renderShipping(order.shippingAmount)}
			</div>
		);
	}
}